import { MAX_ROOM_PLAYERS } from "../../config/config";
import { RoomAction } from "../../types/action";
import { getPlayers } from "../get-players/get-players";

export function canPlayerJoin(roomActions: RoomAction[]) {
	const players = getPlayers(roomActions);

	if (players.length < MAX_ROOM_PLAYERS) {
		return true;
	}

	return false;
}
