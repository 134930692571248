import { isSamePlayer, useLocalPlayer } from "@dejarik/room-actions";
import { Room } from "twilio-video";

import { useTwilioVideo } from "../../hooks/twilio/use-twilio-video";
import { PlayerStreamComponentProps } from "../../types/video-streaming-consumer-api";
import { VideoStream } from "../video-stream";

export function TwilioPlayerStream({ playerId, room, ...props }: PlayerStreamComponentProps & { room?: Room }) {
	const videRef = useTwilioVideo(room, playerId);
	const localPlayer = useLocalPlayer();

	return <VideoStream {...props} isLocalStream={isSamePlayer({ id: playerId }, localPlayer)} ref={videRef} />;
}
