import { HowlOptions } from "howler";
import { useCallback, useEffect, useMemo } from "react";

import { SoundType } from "../state/sound-context";
import { useHowl } from "./use-howl";
import { useSoundSettings } from "./use-sound-settings";

type SoundOptions = Pick<HowlOptions, "autoplay" | "loop">;

const defaultSoundOptions: SoundOptions = {
	loop: false,
	autoplay: false,
};

export function useSound(type: SoundType, src: string, options?: Partial<SoundOptions>) {
	const settings = useSoundSettings(type);
	const howlOption: HowlOptions = useMemo(
		() => ({ ...defaultSoundOptions, ...options, src, volume: settings.volume, mute: settings.isMuted }),
		[]
	);
	const howl = useHowl(howlOption);

	const handlePlay = useCallback(() => howl?.play(), [howl]);
	const handlePause = useCallback(() => howl?.pause(), [howl]);
	const handleStop = useCallback(() => howl?.stop(), [howl]);

	useEffect(() => {
		howl?.mute(settings.isMuted);
	}, [settings.isMuted]);

	useEffect(() => {
		howl?.volume(settings.volume);
	}, [settings.volume]);

	return {
		play: handlePlay,
		pause: handlePause,
		stop: handleStop,
		howl,
	};
}

export function useSoundEffect(src: string, options?: Partial<SoundOptions>) {
	return useSound("effects", src, options);
}

export function useMusic(src: string, options?: Partial<SoundOptions>) {
	return useSound("music", src, options);
}
