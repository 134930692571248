import { Translate } from "@dejarik/localization";
import { Link } from "react-router-dom";

import { FullHeight } from "../ui/components/full-height";
import { ErrorPageLayout } from "../ui/pages/error";

export default function FourOFourPage() {
	return (
		<FullHeight>
			<ErrorPageLayout>
				<div>
					<span className="text-red-500">404</span>
					<span className="ml-1 font-normal">
						- <Translate k="pageNotFound.headline" />
					</span>
				</div>
				<Link to="/" className="mt-2 block text-sm font-normal underline">
					<Translate k="pageNotFound.linkHomeLabel" />
				</Link>
			</ErrorPageLayout>
		</FullHeight>
	);
}
