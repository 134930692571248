import { RoomAction } from "../../types/action";
import { Player } from "../../types/player";
import { filterActions } from "../../utils/filter-actions/filter-actions";

export function getPlayers(actions: RoomAction[]) {
	const addAndRemovePlayerActions = filterActions(actions, "setup/add-player", "setup/remove-player");

	return addAndRemovePlayerActions.reduce((players, action) => {
		if (action.type === "setup/add-player") {
			return [...players, action.player];
		}

		return players.filter((player) => player.id !== action.playerId);
	}, [] as Player[]);
}
