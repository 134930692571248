import { useCallback, useMemo, useState } from "react";
import { createLocalAudioTrack, createLocalVideoTrack, LocalAudioTrack, LocalVideoTrack } from "twilio-video";

import { VideoTrackStream } from "../../components/video-track-stream";
import { SetDeviceFn } from "../../types/media-streaming-tracks";
import { PreviewStreamComponentProps, VideoStreamingPreviewAPI } from "../../types/video-streaming-preview-api";
import { VideoStreamingPreviewProviderAPI } from "../../types/video-streaming-preview-provider-api";
import { createLocalPreviewTracks } from "../../utils/twilio/create-local-preview-tracks";

export function useTwilioVideoStreamingPreviewAPI(): VideoStreamingPreviewProviderAPI {
	const [status, setStatus] = useState<VideoStreamingPreviewAPI["status"]>("idle");
	const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | undefined>(undefined);
	const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | undefined>(undefined);

	const start = useCallback(() => {
		setStatus("starting");
		createLocalPreviewTracks().then((tracks) => {
			const videoPreviewTrack = tracks.find((track) => track.kind === "video") as LocalVideoTrack | undefined;
			const audioPreviewTrack = tracks.find((track) => track.kind === "audio") as LocalAudioTrack | undefined;

			videoPreviewTrack && setVideoTrack(videoPreviewTrack);
			audioPreviewTrack && setAudioTrack(audioPreviewTrack);
			setStatus("ready");
		});

		return {
			cancel: () => {
				setStatus("idle");
			},
		};
	}, [setStatus, setVideoTrack, setAudioTrack]);

	const stop = useCallback(() => {
		if (videoTrack) {
			videoTrack.stop();
			setVideoTrack(undefined);
		}

		if (audioTrack) {
			audioTrack.stop();
			setAudioTrack(undefined);
		}

		setStatus("idle");
	}, [setStatus, videoTrack, audioTrack]);

	const handleSetVideoDevice: SetDeviceFn = useCallback((deviceID) => {
		videoTrack?.stop();
		createLocalVideoTrack({ deviceId: deviceID }).then(setVideoTrack);
	}, []);

	const handleSetAudioDevice: SetDeviceFn = useCallback((deviceID) => {
		audioTrack?.stop();
		createLocalAudioTrack({ deviceId: deviceID }).then(setAudioTrack);
	}, []);

	const mediaTracks = useMemo(() => {
		if (!videoTrack || !audioTrack) return undefined;

		return {
			video: videoTrack.mediaStreamTrack,
			audio: audioTrack.mediaStreamTrack,
			setVideoDevice: handleSetVideoDevice,
			setAudioDevice: handleSetAudioDevice,
		};
	}, [videoTrack, audioTrack, handleSetVideoDevice, handleSetAudioDevice]);

	const ConnectedFakePlayerPreviewStream = useCallback(
		(props: PreviewStreamComponentProps) => <VideoTrackStream {...props} tracks={videoTrack ? [videoTrack] : []} />,
		[videoTrack]
	);

	return {
		status,
		start,
		stop,
		error: null,
		mediaTracks,
		PreviewStream: ConnectedFakePlayerPreviewStream,
	};
}
