"use client";

import { useContext } from "react";
import invariant from "tiny-invariant";

import { RoomActionsContext } from "../../state/room-actions";

export function useRoomActionsContext() {
	const roomActionsContext = useContext(RoomActionsContext);
	invariant(roomActionsContext, "RoomActionsContext.Provider is missing from the tree");

	return roomActionsContext;
}
