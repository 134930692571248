"use client";

import React, { Children, Fragment } from "react";

import { useCurrentTranslation } from "../hooks/use-current-translation";
import { Translation as TranslationType, TranslationKey } from "../types";
import { replaceWithNode } from "./replace-with-node";

function get(translation: TranslationType, path: TranslationKey, defaultValue: unknown): string {
	return path.split(".").reduce(
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		(o, p) => (o ? o[p] : defaultValue),
		translation
	) as unknown as string;
}

export interface TranslationProps {
	[replaceKey: string]: React.ReactNode;
	k: TranslationKey;
}

export function Translate({ k, ...replacementKeys }: TranslationProps) {
	const translation = useCurrentTranslation();

	let translationString: React.ReactNode[] = [get(translation, k, "MISSING KEY")];

	Object.entries(replacementKeys).forEach(([key, value]) => {
		translationString = replaceWithNode(translationString, key, value);
	});

	return (
		<>
			{Children.map(translationString, (child, index) => (
				<Fragment key={`translation=${index}`}>{child}</Fragment>
			))}
		</>
	);
}
