import { Room } from "twilio-video";

import { useRemoteParticipants } from "./use-remote-participants";

export function useGetParticipantByPlayerId(room: Room | undefined, playerId: string) {
	const remoteParticipants = useRemoteParticipants(room);

	const remoteParticipant = remoteParticipants.find((participant) => participant.identity === playerId);

	if (remoteParticipant) return remoteParticipant;

	if (room?.localParticipant.identity === playerId) {
		return room.localParticipant;
	}
}
