import { RoomAction } from "../../types/action";
import { getRoomStages } from "../../utils/get-room-stages/get-room-stages";

export function currentSetupActionsSelector(roomActions: RoomAction[]) {
	const stages = getRoomStages(roomActions);
	const currentStage = stages.at(-1);
	if (!currentStage) return [];
	if (currentStage.stage === "game") return [];

	return currentStage.actions;
}
