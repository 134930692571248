import { useContext } from "react";
import invariant from "tiny-invariant";

import { VideoStreamingPreviewAPIContext } from "../state/video-streaming-preview-api";

export function useVideoStreamingPreviewAPI() {
	const videoStreamingPreviewAPI = useContext(VideoStreamingPreviewAPIContext);
	invariant(videoStreamingPreviewAPI);

	return videoStreamingPreviewAPI;
}
