import { RoomAction } from "../types/action";
import { filterActions } from "./filter-actions/filter-actions";
import { getCurrentPlayerVoteGameActions } from "./vote-game/get-current-player-vote-game-actions";
import { getMostVotedGameId } from "./vote-game/get-most-voted-game";

export function getVotedGameFromSetupActions<GameId extends string>(setupActions: RoomAction[]): GameId | undefined {
	const voteGameActions = filterActions(setupActions, "setup/vote-game", "setup/revoke-vote-game");
	const playerVoteGameActions = getCurrentPlayerVoteGameActions(voteGameActions);
	return getMostVotedGameId(playerVoteGameActions);
}
