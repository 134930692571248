import { cn } from "@dejarik/utils";
import { ComponentPropsWithoutRef } from "react";

export function FullHeight({ children, className, ...props }: ComponentPropsWithoutRef<"div">) {
	return (
		<div className={cn(className, "h-[100dvh]")} {...props}>
			{children}
		</div>
	);
}
