import {
	AddPlayerAction,
	PlayerIsReadyAction,
	PlayGameAgainAction,
	RemovePlayerAction,
	ReturnToLobbyAction,
	RevokeVoteGameAction,
	StartAction,
	VoteGameAction,
} from "../types/action";
import { Player } from "../types/player";

export function createAddPlayerAction(player: Player): AddPlayerAction {
	return {
		type: "setup/add-player",
		player,
	};
}

export function createRemovePlayerAction(playerId: string): RemovePlayerAction {
	return {
		type: "setup/remove-player",
		playerId,
	};
}

export function createPlayerIsReadyAction(playerId: string): PlayerIsReadyAction {
	return {
		type: "setup/player-is-ready",
		playerId,
	};
}

export function createStartAction(): StartAction {
	return {
		type: "setup/start",
	};
}

export function createVoteGameAction(playerId: string, gameId: string): VoteGameAction {
	return {
		type: "setup/vote-game",
		playerId,
		gameId,
	};
}

export function createRevokeVoteGameAction(playerId: string): RevokeVoteGameAction {
	return {
		type: "setup/revoke-vote-game",
		playerId,
	};
}

export function createPlayAgainAction(playerId: string): PlayGameAgainAction {
	return {
		type: "post-game/play-again",
		playerId,
	};
}

export function createReturnToLobbyAction(playerId: string): ReturnToLobbyAction {
	return {
		type: "post-game/return-to-lobby",
		playerId,
	};
}
