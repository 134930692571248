import { useCallback } from "react";

import { INVITED_BY_QUERY_PARAMETER } from "../utils/get-invited-by-query-parameter";
import { useSharingContext } from "./use-sharing-context";

export function useShareRoom() {
	const { share } = useSharingContext();

	const handleShareRoom = useCallback(
		(hostName: string) => {
			const url = new URL(window.location.origin + window.location.pathname);
			url.searchParams.append(INVITED_BY_QUERY_PARAMETER, hostName);

			void share({
				title: `${hostName} has invited you`,
				text: "Join the room to play some games.",
				url: url.href,
			});
		},
		[share]
	);

	return handleShareRoom;
}
