const labelWithoutPrefixRegex = /([\w\d\s]*)( - )(?<labelWithoutPrefix>.+)/;
const cleanLabelRegex = /(?<cleanLabel>[\w\d\s-]+)(?<suffix>[()\w\d\s-:]*)/;

function getLabelWithoutPrefix(deviceLabel: string) {
	const result = labelWithoutPrefixRegex.exec(deviceLabel);

	const labelWithoutPrefix = result?.groups?.labelWithoutPrefix;
	if (labelWithoutPrefix) return labelWithoutPrefix;

	return deviceLabel;
}

export function getCleanDeviceLabel(deviceLabel: string) {
	const labelWithoutPrefix = getLabelWithoutPrefix(deviceLabel);
	const result = cleanLabelRegex.exec(labelWithoutPrefix);
	if (!result || !result.groups) return labelWithoutPrefix;

	const { cleanLabel, suffix } = result.groups;

	if (suffix === "(Built-in)") return `${cleanLabel} ${suffix}`;

	return cleanLabel;
}
