import { Translate } from "@dejarik/localization";

import { CREATOR, VERSION } from "../../config";
import { CreateNewRoomLink } from "../room/components/create-new-room-link";
import { DefaultPage } from "../ui/pages/default";

export default function WelcomePage() {
	return (
		<DefaultPage
			footer={
				<div className="flex justify-center gap-4 text-xs text-gray-400">
					<div>
						v{VERSION} | by
						<a href={CREATOR.link} target="_blank" className="ml-1 underline" rel="noreferrer">
							{CREATOR.name}
						</a>
					</div>
				</div>
			}
		>
			<div className="flex flex-1 flex-col items-center justify-center">
				<div className="flex h-80 w-80 flex-col items-center justify-center rounded-full">
					<CreateNewRoomLink>
						<h2 className="bg-gradient-to-br from-red-400 to-blue-600 bg-clip-text text-8xl font-black uppercase text-transparent hover:underline">
							<Translate k="welcome.headline" />
						</h2>
					</CreateNewRoomLink>
					<div className="mt-4 text-center text-sm text-gray-500">
						<Translate k="welcome.subtitle" />
					</div>
				</div>
			</div>
		</DefaultPage>
	);
}
