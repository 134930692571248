/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ComponentProps, useEffect, useRef } from "react";

import { VisualTrack } from "../utils/twilio/track-types";

interface VideoTrackStream extends ComponentProps<"video"> {
	tracks: VisualTrack[];
}

export function VideoTrackStream({ tracks, ...props }: VideoTrackStream) {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!tracks.length) return;

		const videoPlayer = videoRef.current;

		tracks.forEach((track) => track.attach(videoRef.current!));
		return () => {
			tracks.forEach((track) => track.detach(videoPlayer!));

			if (videoPlayer) {
				// This addresses a Chrome issue where the number of WebMediaPlayers is limited.
				// See: https://github.com/twilio/twilio-video.js/issues/1528
				videoPlayer.srcObject = null;
			}
		};
	}, [tracks]);

	return <video {...props} ref={videoRef} />;
}
