import { useContext } from "react";
import invariant from "tiny-invariant";

import { LanguageContext } from "../state/language";

export function useLanguageContext() {
	const languageContext = useContext(LanguageContext);
	invariant(languageContext, "LanguageContext.Provider is missing from the tree");

	return languageContext;
}
