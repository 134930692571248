import {
	Button,
	Select,
	SelectContent,
	SelectGroup,
	SelectItemSimple,
	SelectTrigger,
	SelectValue,
	Sheet,
	SheetClose,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "@dejarik/ui";
import { getCleanDeviceLabel, useAvailableDevices } from "@dejarik/video";
import { Mic, Settings, Video } from "lucide-react";
import { ComponentProps, ComponentType } from "react";

interface PreviewStreamingTracksProps {
	videoTrack: MediaStreamTrack;
	audioTrack: MediaStreamTrack;
	selectVideoDevice: (deviceId: string) => void;
	selectAudioDevice: (deviceId: string) => void;
	PreviewStream: ComponentType<ComponentProps<"video">>;
}

export function PreviewStreamingTracks({
	videoTrack,
	audioTrack,
	selectVideoDevice,
	selectAudioDevice,
	PreviewStream,
}: PreviewStreamingTracksProps) {
	const availableDevices = useAvailableDevices([]);

	const availableVideoInputDevices = availableDevices.filter((device) => device.kind === "videoinput");
	const availableAudioInputDevices = availableDevices.filter((device) => device.kind === "audioinput");

	const currentVideoDevice = availableVideoInputDevices.find(
		(videoInputDevice) => videoInputDevice.label === videoTrack.label
	);

	const currentAudioDevice = availableAudioInputDevices.find(
		(audioInputDevice) => audioInputDevice.label === audioTrack.label
	);

	return (
		<div className="relative h-full w-full">
			<PreviewStream className="h-full w-full object-cover" />
			<div className="absolute bottom-2 left-2 right-2 flex items-center justify-between rounded-md border border-neutral-200/5 bg-neutral-600/5 p-2 backdrop-blur-md">
				<div className="flex flex-col gap-1">
					<div className="flex gap-2">
						<Video size={16} className="text-neutral-300" />
						<span className="text-xs">
							{!currentVideoDevice && <span className="text-neutral-100">No video device</span>}
							{currentVideoDevice && <span>{getCleanDeviceLabel(currentVideoDevice.label)}</span>}
						</span>
					</div>
					<div className="flex gap-2">
						<Mic size={16} className="text-neutral-300" />
						<span className="text-xs">
							{!currentAudioDevice && <span className="text-neutral-100">No selected mic</span>}
							{currentAudioDevice && <span>{getCleanDeviceLabel(currentAudioDevice.label)}</span>}
						</span>
					</div>
				</div>
				<Sheet>
					<SheetTrigger className="p-1">
						<Settings size={22} className="text-gray-100" />
					</SheetTrigger>
					<SheetContent side="bottom" className="flex flex-col gap-2">
						<SheetHeader>
							<SheetTitle>Change Camera and Microphone</SheetTitle>
							<SheetDescription>
								This action cannot be undone. This will permanently delete your account and remove your data from our
								servers.
							</SheetDescription>
						</SheetHeader>
						<div className="flex flex-col gap-2">
							<Select value={currentVideoDevice?.deviceId} onValueChange={selectVideoDevice}>
								<SelectTrigger>
									<SelectValue placeholder="Camera" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										{availableVideoInputDevices.map((videoInputDevice) => (
											<SelectItemSimple key={videoInputDevice.deviceId} value={videoInputDevice.deviceId}>
												{videoInputDevice.label}
											</SelectItemSimple>
										))}
									</SelectGroup>
								</SelectContent>
							</Select>
							<Select value={currentAudioDevice?.deviceId} onValueChange={selectAudioDevice}>
								<SelectTrigger>
									<SelectValue placeholder="Microphone" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										{availableAudioInputDevices.map((audioInputDevice) => (
											<SelectItemSimple key={audioInputDevice.deviceId} value={audioInputDevice.deviceId}>
												{audioInputDevice.label}
											</SelectItemSimple>
										))}
									</SelectGroup>
								</SelectContent>
							</Select>
						</div>
						<SheetFooter>
							<SheetClose asChild>
								<Button>Done</Button>
							</SheetClose>
						</SheetFooter>
					</SheetContent>
				</Sheet>
			</div>
		</div>
	);
}
