import { RevokeVoteGameAction, VoteGameAction } from "../../types/action";

function removePlayerVoteGameActions(actions: VoteGameAction[], playerId: string) {
	return actions.filter((action) => action.playerId !== playerId);
}

export function getCurrentPlayerVoteGameActions(voteGameActions: (VoteGameAction | RevokeVoteGameAction)[]) {
	return voteGameActions.reduce((currentPlayerVoteGameActions, action) => {
		if (action.type === "setup/revoke-vote-game") {
			return removePlayerVoteGameActions(currentPlayerVoteGameActions, action.playerId);
		}

		const newCurrentPlayerVoteGameActions = removePlayerVoteGameActions(currentPlayerVoteGameActions, action.playerId);
		newCurrentPlayerVoteGameActions.push(action);
		return newCurrentPlayerVoteGameActions;
	}, [] as VoteGameAction[]);
}
