import { RoomAction } from "../../types/action";
import { Player } from "../../types/player";
import { isSamePlayer } from "../../utils/is-same-player/is-same-player";
import { getPlayers } from "../get-players/get-players";

export function isPlayerInRoom(actions: RoomAction[], player: Player) {
	const players = getPlayers(actions);

	return players.some((joinedPlayer) => isSamePlayer(joinedPlayer, player));
}
