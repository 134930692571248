import { Player } from "../../types/player";
import { RoomStage } from "../../types/room-stage";
import { allPlayersDidAction } from "../all-players-did-action/all-players-did-action";
import { filterActions } from "../filter-actions/filter-actions";
import { allPlayersDidVoteForGame } from "../vote-game/all-players-did-vote-for-game";
import { getCurrentPlayerVoteGameActions } from "../vote-game/get-current-player-vote-game-actions";
import { getMostVotedGameId } from "../vote-game/get-most-voted-game";

export type StageResult = "ready-to-game" | "return-to-lobby" | "replay-game";

export function getRoomStageResult(players: Player[], roomStage: RoomStage): StageResult | null {
	const everyPlayerHasVotedForGame = allPlayersDidVoteForGame(players, roomStage.actions);

	const voteGameActions = filterActions(roomStage.actions, "setup/vote-game", "setup/revoke-vote-game");
	const currentVoteGameActions = getCurrentPlayerVoteGameActions(voteGameActions);
	const mostVotedGameId = getMostVotedGameId(currentVoteGameActions);

	if (everyPlayerHasVotedForGame && !!mostVotedGameId) return "ready-to-game";

	const postGameActions = filterActions(roomStage.actions, "post-game/play-again", "post-game/return-to-lobby");
	const allPlayersDidPostGameAction = allPlayersDidAction(players, postGameActions);
	if (allPlayersDidPostGameAction) {
		const playAgainActions = filterActions(postGameActions, "post-game/play-again");
		const returnToLobbyActions = filterActions(postGameActions, "post-game/return-to-lobby");
		if (playAgainActions.length > returnToLobbyActions.length) {
			return "replay-game";
		} else {
			return "return-to-lobby";
		}
	}

	return null;
}
