import { battleWordleGameDescriptor } from "./battle-wordle";
import { chessGameDescriptor } from "./chess";
import { werewolfGameDescriptor } from "./werewolf";

export * from "./games";
export * from "./components/game-title";
export * from "./components/game-icon";

export const games = [werewolfGameDescriptor, battleWordleGameDescriptor, chessGameDescriptor] as const;

export type GameId = (typeof games)[number]["id"];
export type Game = (typeof games)[number];
