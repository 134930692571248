import { ReactNode } from "react";

import { SharingContext } from "../state/sharing-context";

interface NativeSharingContextProvider {
	children: ReactNode;
}

export function NativeSharingContextProvider({ children }: NativeSharingContextProvider) {
	const handleSharing = async (data: ShareData) => {
		try {
			await navigator.share(data);
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<SharingContext.Provider value={{ hasSharingCapabilities: "share" in navigator, share: handleSharing }}>
			{children}
		</SharingContext.Provider>
	);
}
