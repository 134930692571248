import { useEffect, useState } from "react";
import { RemoteParticipant, Room } from "twilio-video";

export function useRemoteParticipants(room: Room | undefined) {
	const [remoteParticipants, setRemoteParticipants] = useState<RemoteParticipant[]>([]);

	useEffect(() => {
		if (!room) return;

		function handleParticipantConnected(newParticipant: RemoteParticipant) {
			setRemoteParticipants((participants) => [...participants, newParticipant]);
		}

		function handleParticipantDisconnected(disconnectedParticipant: RemoteParticipant) {
			setRemoteParticipants((participants) =>
				participants.filter((participant) => participant.sid !== disconnectedParticipant.sid)
			);
		}

		room.on("participantConnected", handleParticipantConnected);
		room.on("participantDisconnected", handleParticipantDisconnected);

		setRemoteParticipants([...room.participants.values()]);

		return () => {
			setRemoteParticipants([]);
			room.off("participantConnected", handleParticipantConnected);
			room.off("participantDisconnected", handleParticipantDisconnected);
		};
	}, [room]);

	return remoteParticipants;
}
