import { lazy } from "react";

import { GameDescriptor } from "../game-descriptor";

export const werewolfGameDescriptor = {
	id: "werewolf",
	status: "beta",
	playerCount: {
		min: 6,
		max: 16,
	},
	getGameLengthInSeconds: (_numberOfPlayers) => {
		return 60 * 25;
	},
	game: lazy(() => import("./game")),
} as const satisfies GameDescriptor;
