import { VoteGameAction } from "../../types/action";

function findSimpleMajorityKey<K>(votes: Map<K, number>): K | undefined {
	// Step 1: Calculate the total number of votes
	let totalVotes = 0;
	for (const voteCount of votes.values()) {
		totalVotes += voteCount;
	}

	// Step 2: Iterate through the map and find the key with simple majority
	for (const [key, voteCount] of votes.entries()) {
		if (voteCount > totalVotes / 2) {
			return key; // Step 3: Return the key with simple majority
		}
	}

	// No simple majority found
	return undefined;
}

export function getMostVotedGameId<GameId extends string>(voteGameActions: VoteGameAction[]): GameId | undefined {
	if (voteGameActions.length === 0) return undefined;

	const votes = new Map<GameId, number>();

	voteGameActions.forEach((action) => {
		if (votes.has(action.gameId as GameId)) {
			const currentVotes = votes.get(action.gameId as GameId) as number;
			votes.set(action.gameId as GameId, currentVotes + 1);
		} else {
			votes.set(action.gameId as GameId, 1);
		}
	});

	return findSimpleMajorityKey<GameId>(votes);
}
