import { Player } from "@dejarik/room-actions";
import { useVideoStreamingAPI } from "@dejarik/video";

interface SimplePlayerVideoProps {
	player: Player;
}

export function SimplePlayerVideo({ player }: SimplePlayerVideoProps) {
	const { PlayerStream } = useVideoStreamingAPI();

	return (
		<div className="relative isolate h-full overflow-hidden rounded-lg">
			<PlayerStream className="z-10 h-full w-full object-cover" playerId={player.id} />

			<div className="absolute bottom-0 left-0 right-0 flex justify-center">
				<div className="... truncate rounded-t bg-gray-900 bg-opacity-20 px-2 font-sans text-xs text-gray-50">
					{player.name}
				</div>
			</div>
		</div>
	);
}
