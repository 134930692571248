import { isSamePlayer, useLocalPlayer } from "@dejarik/room-actions";
import { useEffect, useState } from "react";
import { RemoteTrackPublication, Room, TwilioError } from "twilio-video";

import {
	getVisualTracksFromLocalParticipant,
	getVisualTracksFromRemoteParticipant,
} from "../../utils/twilio/get-track";
import { isLocalParticipant } from "../../utils/twilio/is-local-participant";
import { VisualTrack } from "../../utils/twilio/track-types";
import { useGetParticipantByPlayerId } from "./use-get-participant-by-player-id";

export function useTracks(room: Room | undefined, playerId: string) {
	const localPlayer = useLocalPlayer();
	const isLocalPlayer = isSamePlayer(localPlayer, { id: playerId });
	const participant = useGetParticipantByPlayerId(room, playerId);

	const [tracks, setTracks] = useState<VisualTrack[]>([]);

	useEffect(() => {
		if (!participant) return;

		function onNewTrack(track: VisualTrack) {
			setTracks((tracks) => [...tracks, track]);
		}

		function onRemoveTrack(removedTrack: VisualTrack) {
			setTracks((tracks) => tracks.filter((track) => track.name !== removedTrack.name));
		}

		function handleSubscriptionFail(error: TwilioError, publication: RemoteTrackPublication) {
			console.error("Could not subscribe to track");
			console.error(error);
			console.log(publication);
		}

		if (isLocalParticipant(participant, isLocalPlayer)) {
			setTracks(getVisualTracksFromLocalParticipant(participant));

			return () => setTracks([]);
		}

		setTracks(getVisualTracksFromRemoteParticipant(participant));

		participant.on("trackSubscribed", onNewTrack);
		participant.on("trackUnsubscribed", onRemoveTrack);
		participant.on("trackSubscriptionFailed", handleSubscriptionFail);

		return () => {
			setTracks([]);
			participant.off("trackSubscribed", onNewTrack);
			participant.off("trackUnsubscribed", onRemoveTrack);
			participant.off("trackSubscriptionFailed", handleSubscriptionFail);
		};
	}, [participant?.sid]);

	return tracks;
}
