import { useContext } from "react";
import invariant from "tiny-invariant";

import { ErrorHandlingContext } from "../state/error-handling";

export function useErrorHandlingContext() {
	const errorHandlingContext = useContext(ErrorHandlingContext);
	invariant(errorHandlingContext, "ErrorHandlingContext.Provider is missing from tree");

	return errorHandlingContext;
}
