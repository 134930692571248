"use client";

import { createContext } from "react";

import { ShortcutKey } from "../utils/keyboard-shortcuts";

export type Shortcut = {
	name: string;
	category?: string;
	description: string;
};

export type ShortcutMap = {
	[key: string]: Shortcut;
};

type KeyboardShortcutsContext = {
	shortcuts: ShortcutMap;
	registerShortcut: (key: ShortcutKey, shortcut: Shortcut) => void;
	unregisterShortcut: (key: ShortcutKey) => void;
};

export const KeyboardShortcutsContext = createContext<KeyboardShortcutsContext | undefined>(undefined);
