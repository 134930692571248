import { DefaultLanguageProvider } from "@dejarik/localization";
import * as Sentry from "@sentry/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import FourOFourPage from "./modules/pages/404";
import { ErrorPage } from "./modules/pages/error";
import { ExplainerPage } from "./modules/pages/explainer";
import HomePage from "./modules/pages/home";
import RoomPage from "./modules/pages/room";
import { setupSentry } from "./modules/sentry/setup-sentry";

setupSentry();
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: (
			<Sentry.ErrorBoundary fallback={ErrorPage}>
				<HomePage />
			</Sentry.ErrorBoundary>
		),
	},
	{
		path: "/what-is-this",
		element: (
			<Sentry.ErrorBoundary fallback={ErrorPage}>
				<ExplainerPage />
			</Sentry.ErrorBoundary>
		),
	},
	{
		path: "/room/:roomId",
		element: (
			<Sentry.ErrorBoundary fallback={ErrorPage}>
				<RoomPage />
			</Sentry.ErrorBoundary>
		),
	},
	{
		path: "*",
		element: <FourOFourPage />,
	},
]);

export function App() {
	return (
		<>
			<DefaultLanguageProvider>
				<RouterProvider router={router} />
			</DefaultLanguageProvider>
		</>
	);
}
