import { useSoundContext } from "@dejarik/sound";
import { ComponentPropsWithRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

export interface VideoStreamProps extends ComponentPropsWithRef<"video"> {
	isHidden?: boolean;
	isLocalStream: boolean;
	zoomLevel?: 1 | 2 | 3;
}

export const VideoStream = forwardRef<HTMLVideoElement, VideoStreamProps>(
	({ zoomLevel = 1, isLocalStream, className, ...props }, ref) => {
		const videoRef = useRef<HTMLVideoElement>(null);
		const { settings } = useSoundContext();
		const { isMuted, volume } = settings["voice-comms"];

		useEffect(() => {
			if (!videoRef.current) return;
			if (isLocalStream) return;

			if (isMuted) {
				videoRef.current.muted = true;
			} else {
				videoRef.current.muted = false;
			}
		}, [isMuted, isLocalStream]);

		useEffect(() => {
			if (!videoRef.current) return;

			videoRef.current.volume = volume;
		}, [volume]);

		useImperativeHandle<HTMLVideoElement | null, HTMLVideoElement | null>(ref, () => videoRef.current);

		return (
			<VideoElement
				ref={videoRef}
				{...props}
				autoPlay
				loop
				playsInline
				$mirrored={isLocalStream}
				$zoomLevel={zoomLevel}
				muted={isLocalStream || isMuted}
				className={`${className} h-full w-full object-cover`}
			/>
		);
	}
);

VideoStream.displayName = "VideoStream";

const VideoElement = styled.video<{
	$mirrored: boolean;
	$zoomLevel: number;
}>`
	object-fit: cover;

	width: 100%;
	height: 100%;

	transform: ${({ $mirrored }) => ($mirrored ? "rotateY(180deg)" : "rotateY(0deg)")};
`;
