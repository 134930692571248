import { NativeSharingContextProvider, Room, RoomIDProvider, RoomLinkContext, RoomLinkProps } from "@dejarik/room";
import { LocalStorageLocalPlayerProvider, RoomActionsContext } from "@dejarik/room-actions";
import { SoundContextProvider } from "@dejarik/sound";
import { CompleteTwilioVideoStreamingAPIProvider, NativeVideoPermissionsProvider } from "@dejarik/video";
import { Link, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useFirebaseRoomActions } from "../firebase/hooks/use-room-actions";
import { SentryErrorHandlingProvider } from "../room/providers/sentry-error-handling";
import { fetchAccessToken } from "../room/utils/fetch-access-token";
import { FullHeight } from "../ui/components/full-height";

function RoomLink({ target, ...props }: RoomLinkProps) {
	if (target === "explainerPage") {
		return <Link {...props} to="/what-is-this" />;
	}

	throw new Error(`RoomLink does not support this target (${target})`);
}

export default function RoomPage() {
	const { roomId } = useParams();
	invariant(roomId, "RoomID is missing");

	const { roomActions, addAction } = useFirebaseRoomActions(roomId);

	return (
		<FullHeight>
			<RoomIDProvider roomID={roomId}>
				<RoomLinkContext.Provider value={RoomLink}>
					<LocalStorageLocalPlayerProvider localStorageKey="local-player">
						<SentryErrorHandlingProvider>
							<RoomActionsContext.Provider value={{ roomActions, addAction }}>
								<NativeVideoPermissionsProvider>
									<CompleteTwilioVideoStreamingAPIProvider getAccessToken={fetchAccessToken}>
										<NativeSharingContextProvider>
											<SoundContextProvider>
												<Room />
											</SoundContextProvider>
										</NativeSharingContextProvider>
									</CompleteTwilioVideoStreamingAPIProvider>
								</NativeVideoPermissionsProvider>
							</RoomActionsContext.Provider>
						</SentryErrorHandlingProvider>
					</LocalStorageLocalPlayerProvider>
				</RoomLinkContext.Provider>
			</RoomIDProvider>
			<div className="portal-container"></div>
		</FullHeight>
	);
}
