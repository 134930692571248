import { RoomAction } from "../../types/action";
import { Player } from "../../types/player";
import { isSamePlayer } from "../is-same-player/is-same-player";

type PlayerAction = Extract<RoomAction, { playerId: string }>;

export function allPlayersDidAction<Action extends PlayerAction>(players: Player[], actions: Action[]) {
	if (!players.length) return false;

	return players.every((player) => actions.some((action) => isSamePlayer({ id: action.playerId }, player)));
}
