import { RoomAction } from "../../types/action";
import { Player } from "../../types/player";
import { filterActions } from "../filter-actions/filter-actions";
import { getCurrentPlayerVoteGameActions } from "./get-current-player-vote-game-actions";

export function allPlayersDidVoteForGame(players: Player[], currentActions: RoomAction[]) {
	const voteGameActions = filterActions(currentActions, "setup/vote-game", "setup/revoke-vote-game");

	return getCurrentPlayerVoteGameActions(voteGameActions).length === players.length;
}
