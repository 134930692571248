import { useContext } from "react";
import invariant from "tiny-invariant";

import { RoomLinkContext, RoomLinkProps } from "../state/room-link";

export function RoomLink(props: RoomLinkProps) {
	const RoomLink = useContext(RoomLinkContext);
	invariant(RoomLink, "RoomLinkContext.Provider is missing from the tree");

	return <RoomLink {...props} />;
}
