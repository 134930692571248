import { useLocalPlayerContext, useRoomActions } from "@dejarik/room-actions";

import { getCurrentRoomStage } from "../utils/get-current-room-stage/get-current-room-stage";

export function useCurrentRoomStage() {
	const { player: localPlayer } = useLocalPlayerContext();
	const roomActions = useRoomActions();

	return getCurrentRoomStage(localPlayer, roomActions);
}
