import { useContext } from "react";
import invariant from "tiny-invariant";

import { SoundContext } from "../state/sound-context";

export function useSoundContext() {
	const soundContext = useContext(SoundContext);
	invariant(soundContext, "SoundContext.Provider is missing from tree");

	return soundContext;
}
