import { v4 } from "@dejarik/utils";
import { useEffect, useState } from "react";
import { Link, LinkProps } from "react-router-dom";

export function CreateNewRoomLink({ children, ...props }: Omit<LinkProps, "to">) {
	const [roomId, setRoomId] = useState("");

	useEffect(() => {
		setRoomId(v4());
	}, []);

	return (
		<Link to={`/room/${roomId}`} {...props}>
			{children}
		</Link>
	);
}
