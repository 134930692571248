import invariant from "tiny-invariant";

import { RoomAction } from "../../types/action";
import { Player } from "../../types/player";
import { filterActions } from "../../utils/filter-actions/filter-actions";
import { getRoomStages } from "../../utils/get-room-stages/get-room-stages";

export function isPlayerReady(actions: RoomAction[], player: Player) {
	const latestStage = getRoomStages(actions).at(-1);
	invariant(latestStage);

	const readyActions = filterActions(latestStage.actions, "setup/player-is-ready");

	return readyActions.some((action) => action.playerId === player.id);
}
