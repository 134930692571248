import invariant from "tiny-invariant";

import { useLocalPlayerContext } from "./use-local-player-context";

export function useLocalPlayer() {
	const { player } = useLocalPlayerContext();
	invariant(player, "Local player is not set in context");

	return player;
}
