import { createContext } from "react";

export type SoundSettings = {
	isMuted: boolean;
	volume: number;
};

export type SoundType = "effects" | "music" | "voice-comms";

export type SoundContextType = {
	settings: {
		[type in SoundType]: SoundSettings;
	};
	setSoundSettings: (type: SoundType, settings: SoundSettings) => void;
};

export const SoundContext = createContext<SoundContextType | undefined>(undefined);
