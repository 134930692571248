import { Translate } from "@dejarik/localization";
import { createAddPlayerAction, useCurryAddRoomAction, useLocalPlayer } from "@dejarik/room-actions";
import { Button } from "@dejarik/ui";

export function LeftRoom() {
	const localPlayer = useLocalPlayer();

	const rejoinRoom = useCurryAddRoomAction(createAddPlayerAction);

	return (
		<main className="@container relative h-full w-full bg-emerald-800 p-1">
			<div className="@lg:items-center @lg:gap-12 @lg:justify-center mx-auto flex h-full flex-col overflow-hidden">
				<div className="@lg:flex @lg:flex-col @lg:items-center @lg:flex-initial flex-1">
					<h1 className="text-7xl font-extrabold leading-[4rem] tracking-tight text-emerald-50">
						<Translate k="room.leftRoom.headline" />
					</h1>
					<p className="@lg:max-w-lg @lg:text-center mt-8 text-indigo-100">
						<Translate k="room.leftRoom.hint" />
					</p>
				</div>
				<div className="flex flex-col gap-1">
					<p className="text-sm text-emerald-50">
						<Translate k="room.leftRoom.rejoinHint" />
					</p>
					<Button className="w-full" onClick={() => rejoinRoom(localPlayer)}>
						Rejoin Room
					</Button>
				</div>
			</div>
		</main>
	);
}
