import { Translate } from "@dejarik/localization";

import { FullHeight } from "../ui/components/full-height";
import { ErrorPageLayout } from "../ui/pages/error";

export function ErrorPage() {
	return (
		<FullHeight>
			<ErrorPageLayout>
				<div>
					<h1 className="font-medium">
						<Translate k="error.headline" />
					</h1>
				</div>
			</ErrorPageLayout>
		</FullHeight>
	);
}
