import { cn } from "@dejarik/utils";
import { useMeasure } from "@react-hookz/web";
import { Children, ComponentPropsWithoutRef, CSSProperties, useMemo } from "react";

type Dimensions = {
	width: number;
	height: number;
};

type GridOptions = {
	minNumberOfElements: number;
	elementSize: {
		min: number;
		max: number;
	};
};

function getGridDimensions(
	{ width, height }: Dimensions,
	{ minNumberOfElements, elementSize }: GridOptions
): [number, number] {
	const smallestDimension = Math.min(height, width);

	// Step 2: Determine the square size. It should be within max and min limits
	// If smallestDimension is less than minSize then no exact division is possible
	if (smallestDimension < elementSize.min) {
		throw "No exact divisions possible as smallestDimension is less than minSize.";
	}
	// Choose size which is less or equal to smallestDimension but not more than maxSize
	let squareSize = Math.min(smallestDimension, elementSize.max);

	// Step 3: Divide the rectangular space into squares
	let rows = Math.floor(height / squareSize);
	let columns = Math.floor(width / squareSize);

	let totalSquares = rows * columns;

	while (totalSquares < minNumberOfElements) {
		squareSize--; //decrease squareSize to create more squares
		rows = Math.floor(height / squareSize);
		columns = Math.floor(width / squareSize);
		totalSquares = rows * columns;
	}

	return [columns, rows];
}

function getGridStyle(dimensions: Dimensions, gridOptions: GridOptions): CSSProperties {
	const [numberOfColumns, numberOfRows] = getGridDimensions(dimensions, gridOptions);

	return {
		gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
		gridTemplateRows: `repeat(${numberOfRows}, minmax(0, 1fr))`,
	};
}

export function VideoWall({ children, className }: ComponentPropsWithoutRef<"ol">) {
	const numberOfElements = Children.count(children);
	const [measures, ref] = useMeasure<HTMLOListElement>();

	const gridStyle = useMemo(() => {
		const MIN_ELEMENT_SIZE = 60;
		const dimensions: Dimensions = {
			width: measures?.width ?? MIN_ELEMENT_SIZE,
			height: measures?.height ?? MIN_ELEMENT_SIZE,
		};

		const gridOptions: GridOptions = {
			minNumberOfElements: numberOfElements,
			elementSize: {
				min: MIN_ELEMENT_SIZE,
				max: 7 * MIN_ELEMENT_SIZE,
			},
		};

		return getGridStyle(dimensions, gridOptions);
	}, [measures?.width, measures?.height, numberOfElements]);

	return (
		<ol
			key="video-wall"
			ref={ref}
			className={cn(`${className} grid h-full gap-0.5`, { ["gap-2"]: (measures?.width ?? 0) > 720 })}
			style={gridStyle}
		>
			{children}
		</ol>
	);
}
