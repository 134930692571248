/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef } from "react";
import { Room } from "twilio-video";

import { useTracks } from "./use-tracks";

export function useTwilioVideo(room: Room | undefined, playerID: string) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const tracks = useTracks(room, playerID);

	useEffect(() => {
		if (!tracks.length) return;

		const videoPlayer = videoRef.current;

		tracks.forEach((track) => track.attach(videoRef.current!));
		return () => {
			tracks.forEach((track) => track.detach(videoPlayer!));

			if (videoPlayer) {
				// This addresses a Chrome issue where the number of WebMediaPlayers is limited.
				// See: https://github.com/twilio/twilio-video.js/issues/1528
				videoPlayer.srcObject = null;
			}
		};
	}, [tracks]);

	return videoRef;
}
