import { useContext } from "react";
import invariant from "tiny-invariant";

import { VideoStreamingAPIContext } from "../state/video-streaming-api";

export function useVideoStreamingAPI() {
	const videoStreamingAPI = useContext(VideoStreamingAPIContext);
	invariant(videoStreamingAPI);

	return videoStreamingAPI;
}
