"use client";

import { createContext } from "react";

import { SupportedLanguage } from "../supported-languages";

type LanguageContext = {
	selectedLanguage: SupportedLanguage;
	setSelectedLanguage: (language: SupportedLanguage) => void;
	availableLanguages: SupportedLanguage[];
};

export const LanguageContext = createContext<LanguageContext | undefined>(undefined);
