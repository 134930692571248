import { VideoStreamingPreviewAPIContext } from "../state/video-streaming-preview-api";
import { VideoStreamingPreviewAPI } from "../types/video-streaming-preview-api";

interface VideoStreamingPreviewAPIProviderProps {
	videoStreamingPreviewAPI: VideoStreamingPreviewAPI;
	children: React.ReactNode;
}

export function VideoStreamingPreviewAPIProvider({
	videoStreamingPreviewAPI,
	children,
}: VideoStreamingPreviewAPIProviderProps) {
	return (
		<VideoStreamingPreviewAPIContext.Provider value={videoStreamingPreviewAPI}>
			{children}
		</VideoStreamingPreviewAPIContext.Provider>
	);
}
