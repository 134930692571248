import { type Database, off, onValue, ref } from "firebase/database";

import { getListFromFirebaseSnapshot } from "./get-list-from-snapshot";

type StateType = "list" | "object";

export function listenToFirebaseStateChanges<State>(
	firebaseDB: Database,
	refPath: string,
	onChange: (state?: State) => void,
	type: StateType = "list"
) {
	const dbRef = ref(firebaseDB, refPath);

	onValue(dbRef, (snapshot) => {
		if (snapshot.exists()) {
			if (type === "list") {
				const list = getListFromFirebaseSnapshot(snapshot);
				return onChange(list as unknown as State);
			}

			const state = snapshot.val();
			onChange(state);
		} else {
			onChange();
		}
	});

	return () => off(dbRef);
}
