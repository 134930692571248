import { lazy } from "react";

import { GameDescriptor } from "../game-descriptor";

export const chessGameDescriptor = {
	id: "chess",
	status: "stable",
	playerCount: {
		min: 2,
		max: 2,
	},
	getGameLengthInSeconds: (_numberOfPlayers) => {
		return 60 * 30;
	},
	game: lazy(() => import("./game")),
} as const satisfies GameDescriptor;
