import { VideoStreamingAPIContext } from "../state/video-streaming-api";
import { VideoStreamingAPI } from "../types/video-streaming-consumer-api";

interface VideoStreamingAPIProviderProps {
	videoStreamingAPI: VideoStreamingAPI;
	children: React.ReactNode;
}

export function VideoStreamingAPIProvider({ videoStreamingAPI, children }: VideoStreamingAPIProviderProps) {
	return <VideoStreamingAPIContext.Provider value={videoStreamingAPI}>{children}</VideoStreamingAPIContext.Provider>;
}
