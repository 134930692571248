import { useNativeCameraPermission } from "../hooks/use-native-video-permission";
import { VideoPermissionsContext } from "../state/video-permissions";

interface NativeVideoPermissionsProviderProps {
	children: React.ReactNode;
}

export function NativeVideoPermissionsProvider({ children }: NativeVideoPermissionsProviderProps) {
	const permissionAPI = useNativeCameraPermission();

	return <VideoPermissionsContext.Provider value={permissionAPI}>{children}</VideoPermissionsContext.Provider>;
}
