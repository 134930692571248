import { useContext } from "react";

import { useConnectToVideoStreaming } from "../hooks/use-connect-to-video-streaming/use-connect-to-video-streaming";
import { VideoStreamingAPIContext } from "../state/video-streaming-api";

interface ConnectToVideoRoomProps {
	roomID: string;
	children: React.ReactNode;
}

export function ConnectToVideoRoom({ roomID, children }: ConnectToVideoRoomProps) {
	const videoStreamingAPI = useContext(VideoStreamingAPIContext);

	useConnectToVideoStreaming(roomID, videoStreamingAPI);

	return <>{children}</>;
}
