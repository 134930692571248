import { ErrorHandlingContext, GenericErrorBoundary } from "@dejarik/utils";
import * as Sentry from "@sentry/react";

interface SentryErrorHandlingProviderProps {
	children: React.ReactNode;
}

export function SentryErrorHandlingProvider({ children }: SentryErrorHandlingProviderProps) {
	return (
		<ErrorHandlingContext.Provider
			value={{
				ErrorBoundary: Sentry.ErrorBoundary as GenericErrorBoundary,
				reportError: Sentry.captureException,
			}}
		>
			{children}
		</ErrorHandlingContext.Provider>
	);
}
