import { useMemo } from "react";

import { RoomAction } from "../../types/action";
import { useRoomActions } from "./use-room-actions";

type StateSelectorFunction<State, SelectorArguments extends unknown[]> = (
	roomActions: RoomAction[],
	...args: SelectorArguments
) => State;

export function useSelectRoomActionsState<State, SelectorArguments extends unknown[]>(
	getState: StateSelectorFunction<State, SelectorArguments>,
	...args: SelectorArguments
) {
	const roomActions = useRoomActions();
	const state = useMemo(() => getState(roomActions, ...args), [roomActions, getState, args]);

	return state;
}
