function replaceStringWithNode(
	text: string,
	replacementKey: string,
	replacement: React.ReactNode
): React.ReactNode[] {
	const searchKey = "${" + replacementKey + "}";
	const index = text.indexOf(searchKey);

	if (index === -1) return [text];

	const tail = text.substring(index + searchKey.length);
	if (index === 0)
		return [
			replacement,
			...replaceWithNode([tail], replacementKey, replacement),
		];

	const head = text.substring(0, index);

	return [
		head,
		replacement,
		...replaceWithNode([tail], replacementKey, replacement),
	];
}

export function replaceWithNode(
	text: React.ReactNode | React.ReactNode[],
	replacementKey: string,
	replacement: React.ReactNode
): React.ReactNode[] {
	if (Array.isArray(text)) {
		return text.flatMap((subtext) => {
			if (typeof subtext === "string")
				return replaceStringWithNode(subtext, replacementKey, replacement);

			return subtext;
		});
	}

	if (typeof text === "string") {
		return replaceStringWithNode(text, replacementKey, replacement);
	}

	return [text];
}
