import { useLocalPlayer } from "@dejarik/room-actions";
import { useEffect, useRef } from "react";

import { DisconnectFn, JoinFn, VideoStreamingAPI } from "../../types/video-streaming-consumer-api";

export function useConnectToVideoStreaming(roomID: string, videoStreamingAPI?: VideoStreamingAPI) {
	const localPlayer = useLocalPlayer();
	const joinRef = useRef<JoinFn | undefined>(undefined);
	const disconnectRef = useRef<DisconnectFn | undefined>(undefined);

	useEffect(() => {
		if (videoStreamingAPI?.status === "idle") {
			joinRef.current = videoStreamingAPI.join;
		}

		if (videoStreamingAPI?.status === "connecting") {
			disconnectRef.current = videoStreamingAPI.disconnect;
		}

		if (videoStreamingAPI?.status === "connected") {
			disconnectRef.current = videoStreamingAPI.disconnect;
		}
	}, [videoStreamingAPI]);

	useEffect(() => {
		if (videoStreamingAPI?.status !== "idle") return;

		const joiningPromise = joinRef.current?.(roomID, localPlayer.id);

		return () => joiningPromise?.cancel();
	}, [!videoStreamingAPI, localPlayer]);

	useEffect(() => {
		return () => {
			disconnectRef.current?.();
		};
	}, [!videoStreamingAPI]);
}
