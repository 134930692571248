"use client";

import { createContext } from "react";

export type GenericErrorBoundary = React.ComponentType<{
	onError?: (error: Error) => void;
	fallback: React.ReactNode | React.ComponentType<{ error: Error }>;
	children: React.ReactNode;
}>;

type ErrorHandlingContextType = {
	ErrorBoundary: GenericErrorBoundary;
	reportError: (error: Error) => void;
};

export const ErrorHandlingContext = createContext<ErrorHandlingContextType | undefined>(undefined);
