import { Translate } from "@dejarik/localization";
import { ComponentPropsWithRef } from "react";

import { Game } from "../index";

interface GameTitleProps extends ComponentPropsWithRef<"span"> {
	gameId: Game["id"];
}

export function GameTitle({ gameId, ...props }: GameTitleProps) {
	if (gameId === "werewolf")
		return (
			<span {...props}>
				<Translate k="games.werwolf.title" />
			</span>
		);
	if (gameId === "battle-wordle")
		return (
			<span {...props}>
				<Translate k="games.battleWordle.title" />
			</span>
		);

	if (gameId === "chess")
		return (
			<span {...props}>
				<Translate k="games.chess.title" />
			</span>
		);

	throw new Error(`unsupported Game ${gameId}`);
}
