"use client";

import { SupportedLanguage } from "../supported-languages";

function getPreferredLanguages() {
	if (typeof navigator === "undefined") {
		return ["en"];
	}

	return navigator.languages;
}

export function getDefaultLanguage(supportedLanguages: SupportedLanguage[]) {
	const preferredLanguages = getPreferredLanguages();

	const supportedLanguage = preferredLanguages.reduce<SupportedLanguage | undefined>((result, preferredLanguage) => {
		if (result) return result;

		const supportedLang = supportedLanguages.find(
			(supportedLanguage) => preferredLanguage.indexOf(supportedLanguage) > -1
		);

		return supportedLang;
	}, undefined);

	return supportedLanguage as SupportedLanguage;
}
