import { Game, games } from "@dejarik/games";
import { Translate } from "@dejarik/localization";
import {
	createRevokeVoteGameAction,
	createVoteGameAction,
	currentSetupActionsSelector,
	filterActions,
	getCurrentPlayerVoteGameActions,
	getPlayers,
	useCurryAddRoomAction,
	useLocalPlayer,
	useSelectRoomActionsState,
} from "@dejarik/room-actions";
import { allPlayersDidVoteForGame } from "@dejarik/room-actions/src/utils/vote-game/all-players-did-vote-for-game";
import { Button } from "@dejarik/ui";
import { VideoWall } from "@dejarik/video";
import { motion, Variants } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import invariant from "tiny-invariant";

import { GameGroupHeader } from "../game-group-header/game-group-header";
import { GameVotingButton } from "../game-voting-button";
import { SimplePlayerVideo } from "../simple-player-video";

const container: Variants = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			when: "beforeChildren",
			staggerChildren: 0.1,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			when: "afterChildren",
			staggerChildren: 0.1,
			staggerDirection: -1,
		},
	},
};

const item: Variants = {
	hidden: { opacity: 0, translateY: 50 },
	show: {
		opacity: 1,
		translateY: 0,
		transition: { type: "spring", stiffness: 800, mass: 1, damping: 30 },
	},
	exit: {
		opacity: 0,
		translateY: 50,
		transition: { duration: 0.1 },
	},
};

type GameGroup = {
	minGameTime: number;
	maxGameTime: number;
	games: Game[];
};

function generateDefaultGameGroups(): GameGroup[] {
	return [
		{
			minGameTime: 0,
			maxGameTime: 60 * 20,
			games: [],
		},
		{
			minGameTime: 60 * 20 + 1,
			maxGameTime: Number.POSITIVE_INFINITY,
			games: [],
		},
	];
}

export function GameVotingScreen() {
	const localPlayer = useLocalPlayer();
	const [selectedGame, setSelectedGame] = useState<Game["id"] | undefined>(undefined);
	const players = useSelectRoomActionsState(getPlayers);
	const setupActions = useSelectRoomActionsState(currentSetupActionsSelector);
	const voteForGame = useCurryAddRoomAction(createVoteGameAction);
	const revokeVoteForGame = useCurryAddRoomAction(createRevokeVoteGameAction);

	const handleToggleSelect = useCallback(
		(gameId: Game["id"]) => {
			setSelectedGame((previouslySelectedGameId) => {
				if (previouslySelectedGameId === gameId) return undefined;
				return gameId;
			});
		},
		[setSelectedGame]
	);

	const handleVote = useCallback(() => {
		invariant(selectedGame);
		voteForGame(localPlayer.id, selectedGame);
		setSelectedGame(undefined);
	}, [localPlayer, selectedGame]);

	const handleRevokeVote = useCallback(() => {
		revokeVoteForGame(localPlayer.id);
	}, [localPlayer]);

	const gameGroups: GameGroup[] = useMemo(() => {
		const gameGroups = generateDefaultGameGroups();

		games.forEach((game) => {
			const estimatedGameTime = game.getGameLengthInSeconds(players.length);
			const gameGroup = gameGroups.find(({ minGameTime, maxGameTime }) => {
				if (estimatedGameTime < minGameTime) return false;
				if (estimatedGameTime > maxGameTime) return false;
				return true;
			});

			gameGroup?.games.push(game);
		});

		return gameGroups;
	}, [players.length]);

	const isGameAvailable = useCallback(
		(gameId: Game["id"]) => {
			const game = games.find((game) => game.id === gameId);
			invariant(game);

			if (players.length < game.playerCount.min) return false;
			if (players.length > game.playerCount.max) return false;

			return true;
		},
		[players.length]
	);

	const gameVoteActions = getCurrentPlayerVoteGameActions(
		filterActions(setupActions, "setup/vote-game", "setup/revoke-vote-game")
	);
	const localPlayerVoteGameAction = gameVoteActions.find((action) => action.playerId === localPlayer.id);
	const localPlayerHasVoted = !!localPlayerVoteGameAction;
	const allPlayersHaveVoted = allPlayersDidVoteForGame(players, setupActions);

	return (
		<motion.main
			variants={container}
			initial="hidden"
			animate="show"
			exit="exit"
			className="@container relative flex h-full w-full flex-col items-center gap-4 p-1"
		>
			<div className="@lg:container h-[25%] w-full">
				<VideoWall>
					{players.map((player) => (
						<motion.div key={player.id} variants={item} className="overflow-hidden">
							<SimplePlayerVideo player={player} />
						</motion.div>
					))}
				</VideoWall>
			</div>
			<div className="@lg:container flex w-full flex-1 flex-col gap-2">
				<motion.h1 variants={item} className="text-2xl font-semibold">
					<Translate k="room.gameVoting.headline" />
				</motion.h1>

				<ol className="flex w-full flex-col gap-8">
					{gameGroups.map((gameGroup) => (
						<motion.li variants={item} key={gameGroup.minGameTime} className="flex flex-col gap-1">
							<h2 className="text-sm font-medium text-neutral-600">
								<GameGroupHeader maxGameTime={gameGroup.maxGameTime} />
							</h2>
							<ul className="@lg:grid-cols-4 grid grid-cols-2 gap-1">
								{gameGroup.games.map((game) => (
									<li key={game.id} className="flex w-full flex-col items-center gap-1">
										<GameVotingButton
											game={game}
											isSelected={selectedGame === game.id}
											isSelectionDisabled={localPlayerHasVoted}
											isDisabled={!isGameAvailable(game.id)}
											isVoted={localPlayerVoteGameAction ? localPlayerVoteGameAction.gameId === game.id : false}
											status={game.status}
											onSelect={handleToggleSelect}
											numberOfVotes={gameVoteActions.filter((action) => action.gameId === game.id).length}
										/>
									</li>
								))}
							</ul>
						</motion.li>
					))}
				</ol>
			</div>
			<motion.div variants={item} className="@lg:mx-auto @lg:w-[360px] @lg:gap-2  flex w-full flex-col gap-1">
				{!allPlayersHaveVoted && localPlayerHasVoted && (
					<div className="text-muted-foreground text-center text-sm">
						<Translate k="room.gameVoting.waitForOthersHint" />
					</div>
				)}
				{allPlayersHaveVoted && (
					<div className="text-muted-foreground text-center text-sm">
						<Translate k="room.gameVoting.tieVoteHint" />
					</div>
				)}
				{!localPlayerHasVoted && (
					<Button className="w-full" disabled={selectedGame === undefined} onClick={handleVote}>
						<Translate k="room.gameVoting.voteButtonLabel" />
					</Button>
				)}
				{localPlayerHasVoted && (
					<Button variant="outline" className="w-full" onClick={handleRevokeVote}>
						<Translate k="room.gameVoting.revokeVoteButtonLabel" />
					</Button>
				)}
			</motion.div>
		</motion.main>
	);
}
