import { useErrorHandlingContext } from "@dejarik/utils";
import { useCallback, useMemo, useState } from "react";
import { Room } from "twilio-video";
import { type CancelablePromise } from "twilio-video/tsdef/types";

import { TwilioPlayerStream } from "../../components/twilio/player-stream";
import { MediaStreamingTracks, SetDeviceFn } from "../../types/media-streaming-tracks";
import { PlayerStreamComponentProps, VideoStreamingAPI } from "../../types/video-streaming-consumer-api";
import { VideoStreamingPreviewAPI } from "../../types/video-streaming-preview-api";
import { VideoStreamingProviderAPI } from "../../types/video-streaming-provider-api";
import { joinRoom } from "../../utils/twilio/join-room";

export function useTwilioVideoStreamingAPI(
	getAccessToken: (userId: string, abortSignal: AbortSignal) => Promise<string>,
	videoStreamingPreviewAPI: VideoStreamingPreviewAPI
): VideoStreamingProviderAPI {
	const [room, setRoom] = useState<Room | undefined>(undefined);
	const [status, setStatus] = useState<VideoStreamingAPI["status"]>("idle");
	const [error, setError] = useState<string | null>(null);
	const { reportError } = useErrorHandlingContext();

	const disconnect = useCallback(() => {
		console.log("Disconnect");
		room?.disconnect();
		setRoom(undefined);
		setStatus("idle");
	}, [room]);

	const join = useCallback(
		(roomID: string, playerID: string) => {
			if (room) {
				console.log("💡 Already in room");
				return { cancel: () => null };
			}

			const abortController = new AbortController();
			let joinPromise: CancelablePromise<Room> | undefined = undefined;

			console.log("🪃 Fetching access token");
			getAccessToken(playerID, abortController.signal).then((accessToken) => {
				console.log("✅ Acquired access token", accessToken);
				console.log("📡 Connecting to room");
				setStatus("connecting");

				if (videoStreamingPreviewAPI.status == "ready") {
					joinPromise = joinRoom(
						roomID,
						accessToken,
						videoStreamingPreviewAPI.mediaTracks.video,
						videoStreamingPreviewAPI.mediaTracks.audio
					);
				} else {
					joinPromise = joinRoom(roomID, accessToken);
				}

				joinPromise.then(
					(room) => {
						console.log("✅ Connected to room");
						setRoom(room);
						setStatus("connected");
					},
					(error) => {
						reportError(error);
						setError(error);
						setStatus("error");
					}
				);
			});

			return {
				cancel: () => {
					console.log("🛑 Cancel joining room");
					abortController.abort();
					joinPromise?.cancel();
				},
			};
		},
		[room, videoStreamingPreviewAPI]
	);

	const ConnectedPlayerStream = useCallback(
		(props: PlayerStreamComponentProps) => {
			return <TwilioPlayerStream {...props} room={room} />;
		},
		[room]
	);

	const handleSetVideoDevice: SetDeviceFn = useCallback(() => {
		console.log("No yet implemented");
	}, [room]);

	const handleSetAudioDevice: SetDeviceFn = useCallback(() => {
		console.log("Not yet implemented");
	}, [room]);

	const mediaTracks: MediaStreamingTracks | undefined = useMemo(() => {
		if (!room) return undefined;

		const videoTracks = [...room.localParticipant.videoTracks.values()];
		const audioTracks = [...room.localParticipant.audioTracks.values()];

		return {
			video: videoTracks[0].track.mediaStreamTrack,
			audio: audioTracks[0].track.mediaStreamTrack,
			setVideoDevice: handleSetVideoDevice,
			setAudioDevice: handleSetAudioDevice,
		};
	}, [room, handleSetVideoDevice, handleSetAudioDevice]);

	return {
		status,
		join,
		disconnect,
		error,
		mediaTracks,
		PlayerStream: ConnectedPlayerStream,
	};
}
