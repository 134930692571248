import { useContext } from "react";
import invariant from "tiny-invariant";

import { VideoPermissionsContext } from "../state/video-permissions";

export function useVideoPermissionsContext() {
	const videoPermissionsContext = useContext(VideoPermissionsContext);
	invariant(videoPermissionsContext, "VideoPermissionsContext.Provider is missing from the tree");

	return videoPermissionsContext;
}
