import { Input } from "@dejarik/ui";
import { useFormContext } from "react-hook-form";

export function CapturePlayerName() {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div>
			<Input
				{...register("playerName")}
				placeholder="Tom"
				type="text"
				aria-invalid={errors.playerName ? "true" : "false"}
			/>
		</div>
	);
}
