import { useEffect, useState } from "react";

import { getMediaStream } from "../utils/get-media-stream";
import { stopMediaStream } from "../utils/stop-media-stream";

export function useNativeCameraPermission() {
	const [videoPermission, setVideoPermission] = useState<PermissionState>("prompt");

	const askForPermission = () => {
		getMediaStream().then(
			(stream) => {
				stopMediaStream(stream);
				setVideoPermission("granted");
			},
			(error) => {
				console.log(error);
				setVideoPermission("denied");
			}
		);
	};

	useEffect(() => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			navigator.permissions.query({ name: "camera" }).then((permission) => {
				setVideoPermission(permission.state);

				permission.addEventListener("change", () => setVideoPermission(permission.state));
			});
		} catch (e) {
			console.error(e);
		}
	}, []);

	return {
		permission: videoPermission,
		askForPermission,
	};
}
