import { isPlayerReady, Player, useSelectRoomActionsState } from "@dejarik/room-actions";
import { useVideoError, useVideoStreamingAPI } from "@dejarik/video";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Bot } from "lucide-react";
import { HTMLAttributes } from "react";

interface VideoProps extends HTMLAttributes<HTMLVideoElement> {
	player: Player;
}

export function Video({ player }: VideoProps) {
	const videoError = useVideoError();
	const playerIsReady = useSelectRoomActionsState(isPlayerReady, player);
	const { PlayerStream } = useVideoStreamingAPI();

	const isFake = player?.isFake;

	if (videoError) {
		return (
			<div className="grid h-full place-content-center rounded-lg bg-red-400 text-white">
				<div className="font-medium">Could not connect to video</div>
			</div>
		);
	}

	return (
		<div className="relative isolate h-full overflow-hidden rounded-lg bg-gray-800">
			<PlayerStream className="relative z-10 h-full w-full object-cover" playerId={player.id} />

			<div className="absolute inset-0 z-20 h-full w-full">
				<div className="absolute left-2 top-2 flex items-center gap-1">
					{player && playerIsReady && <CheckCircleIcon className="h-8 w-8 text-green-400" />}
					{isFake && <Bot className="h-6 w-6 text-gray-200" />}
				</div>

				<div className="absolute bottom-0 left-0 right-0 flex justify-center">
					<div className="... truncate rounded-t bg-gray-900 bg-opacity-20 px-2 font-sans text-xs text-gray-50">
						{player.name}
					</div>
				</div>
			</div>
		</div>
	);
}
