import { Translate } from "@dejarik/localization";
import { Button } from "@dejarik/ui";

export function CantJoin() {
	return (
		<main className="@container relative h-full w-full bg-indigo-800 p-1">
			<div className="@lg:items-center @lg:gap-12 @lg:justify-center mx-auto flex h-full flex-col overflow-hidden">
				<div className="@lg:flex @lg:flex-col @lg:items-center @lg:flex-initial flex-1">
					<h1 className="text-7xl font-extrabold leading-[4rem] tracking-tight text-gray-50">
						<Translate k="room.cantJoin.headline" />
					</h1>
					<p className="@lg:max-w-lg @lg:text-center mt-8 text-indigo-100">
						<Translate k="room.cantJoin.description" />
					</p>
				</div>
				<div>
					<Button disabled className="w-full">
						<Translate k="room.cantJoin.startAnotherRoomCta" />
					</Button>
				</div>
			</div>
		</main>
	);
}
