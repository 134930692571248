import { useContext } from "react";

import { PortalContainerContext } from "../state/portal-container";

export function usePortalContainer() {
	const portalContainer = useContext(PortalContainerContext);
	if (portalContainer) return portalContainer;

	if (typeof document === "undefined") {
		return null;
	}

	return document.body;
}
