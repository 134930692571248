import { Translate } from "@dejarik/localization";
import { Checkbox } from "@dejarik/ui";
import { useVideoPermissionsContext } from "@dejarik/video";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export function CaptureCameraPermissions() {
	const { permission, askForPermission } = useVideoPermissionsContext();
	const { watch, setValue } = useFormContext();

	useEffect(() => {
		setValue("cameraPermission", permission, { shouldValidate: true });
	}, [permission]);

	const cameraPermission = watch("cameraPermission");

	return (
		<div>
			<div className="flex items-center">
				<Checkbox
					id="enable-video"
					checked={cameraPermission === "granted"}
					disabled={cameraPermission === "granted"}
					onCheckedChange={askForPermission}
				/>

				<label htmlFor="enable-video" className="ml-2 text-base">
					<Translate k="captureInformation.enableVideoLabel" />
				</label>
			</div>
		</div>
	);
}
