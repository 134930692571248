"use client";

import { useIsPresent } from "framer-motion";
import { useEffect, useRef } from "react";

export function useValueFromPresent<Value>(value: Value) {
	const isPresent = useIsPresent();
	const oldValue = useRef<Value>(value);

	useEffect(() => {
		if (isPresent) {
			oldValue.current = value;
		}
	}, [value, isPresent]);

	return isPresent ? value : oldValue.current;
}
