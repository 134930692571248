import { useEffect, useState } from "react";

export function useAvailableDevices<T>(deps: T[]) {
	const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

	useEffect(() => {
		navigator.mediaDevices.enumerateDevices().then(setDevices);
	}, deps);

	return devices;
}
