"use client";

import { createContext } from "react";

import { Player } from "../types/player";

export type LocalPlayerContext = {
	player: Player | undefined;
	setPlayer: (player: Player) => void;
};

export const LocalPlayerContext = createContext<LocalPlayerContext | undefined>(undefined);
