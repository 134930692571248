import { connect, LocalTrack, LocalVideoTrack } from "twilio-video";

import { getVideoConstraints } from "../get-default-media-constraints";

export function joinRoom(roomId: string, token: string, videoTrack?: MediaStreamTrack, audioTrack?: MediaStreamTrack) {
	const tracks: LocalTrack[] = [];
	if (videoTrack) {
		const localVideoTrack = new LocalVideoTrack(videoTrack);
		tracks.push(localVideoTrack);
	}

	if (audioTrack) {
		const localAudioTrack = new LocalVideoTrack(audioTrack);
		tracks.push(localAudioTrack);
	}

	return connect(token, {
		name: roomId,
		video: getVideoConstraints("default"),
		audio: true,
		bandwidthProfile: {
			video: {
				mode: "grid",
			},
		},
		networkQuality: {
			remote: 1,
			local: 1,
		},
		tracks: tracks.length ? tracks : undefined,
	});
}
