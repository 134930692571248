import { Translate } from "@dejarik/localization";
import {
	createPlayerIsReadyAction,
	createStartAction,
	getHost,
	getPlayers,
	getUnreadyPlayers,
	isPlayerReady,
	isSamePlayer,
	useCurryAddRoomAction,
	useLocalPlayer,
	useSelectRoomActionsState,
} from "@dejarik/room-actions";
import { confirmationSound, useSoundEffect } from "@dejarik/sound";
import { Button } from "@dejarik/ui";
import { VideoWall } from "@dejarik/video";
import { motion, Variants } from "framer-motion";

import { useShareRoom } from "../../hooks/use-share-room";
import { useSharingContext } from "../../hooks/use-sharing-context";
import { Video } from "../video/video";

const waitingStageVariants: Variants = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			when: "beforeChildren",
			staggerChildren: 0.1,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			when: "afterChildren",
			staggerChildren: 0.1,
			staggerDirection: -1,
		},
	},
};

const item: Variants = {
	hidden: { opacity: 0, translateY: 50 },
	show: {
		opacity: 1,
		translateY: 0,
		transition: { type: "spring", stiffness: 800, mass: 1, damping: 30 },
	},
	exit: {
		opacity: 0,
		translateY: 50,
		transition: { duration: 0.1 },
	},
};

const buttonsVariants: Variants = {
	hidden: { y: 100, opacity: 0 },
	show: {
		y: 0,
		opacity: 1,
	},
	exit: {
		y: 100,
		opacity: 0,
	},
};

export function Lobby() {
	const localPlayer = useLocalPlayer();
	const players = useSelectRoomActionsState(getPlayers);
	const hostPlayer = useSelectRoomActionsState(getHost);

	const localPlayerIsHost = isSamePlayer(localPlayer, hostPlayer);

	const localPlayerIsReady = useSelectRoomActionsState(isPlayerReady, localPlayer);
	const unreadyPlayers = useSelectRoomActionsState(getUnreadyPlayers);

	const { hasSharingCapabilities } = useSharingContext();
	const shareRoom = useShareRoom();

	const addPlayerIsReadyAction = useCurryAddRoomAction(createPlayerIsReadyAction);
	const addStartAction = useCurryAddRoomAction(createStartAction);

	const { play: playConfirmationSound } = useSoundEffect(confirmationSound);

	return (
		<motion.div
			key="waiting-stage"
			initial="hidden"
			animate="show"
			exit="exit"
			variants={waitingStageVariants}
			className="@container h-full w-full"
		>
			<div className="@md:p-4 mx-auto flex h-full flex-col p-1">
				<div className="@lg:container flex-1 overflow-hidden">
					<VideoWall className="h-full">
						{players.map((player) => (
							<motion.div key={player.id} variants={item}>
								<Video player={player} />
							</motion.div>
						))}
					</VideoWall>
				</div>

				<motion.div variants={buttonsVariants} className="@lg:mx-auto @lg:w-[360px] @lg:gap-2 mt-1 flex flex-col gap-1">
					{localPlayerIsReady && !localPlayerIsHost && unreadyPlayers.length > 0 && (
						<div className="rounded-md p-2 text-center text-neutral-800">
							<Translate k="room.waiting.waitingForOtherPlayersNotice.explanation" />
						</div>
					)}
					{localPlayerIsReady && !localPlayerIsHost && unreadyPlayers.length === 0 && (
						<div className="rounded-md p-2 text-center text-neutral-800">
							<Translate k="room.waiting.waitForHost" hostName={hostPlayer.name} />
						</div>
					)}

					{hasSharingCapabilities && (
						<Button variant="secondary" className="flex h-auto flex-col" onClick={() => shareRoom(localPlayer.name)}>
							<div className="text-lg font-medium ">
								<Translate k="room.waiting.inviteButton.label" />
							</div>
							<div className="text-muted-foreground text-sm font-normal ">
								<Translate k="room.waiting.inviteButton.subtitle" />
							</div>
						</Button>
					)}

					{!localPlayerIsReady && (
						<Button
							className="flex h-auto flex-col"
							onClick={() => {
								addPlayerIsReadyAction(localPlayer.id);
								playConfirmationSound();
							}}
						>
							<span className="text-lg font-semibold">
								<Translate k="room.waiting.readyButton.label" />
							</span>
						</Button>
					)}
					{localPlayerIsReady && localPlayerIsHost && unreadyPlayers.length === 0 && (
						<Button onClick={addStartAction} className="flex h-auto flex-col">
							<span className="text-lg font-medium">
								<Translate k="room.waiting.hostStartButton.label" />
							</span>
							<span className="text-xs text-blue-100">
								<Translate k="room.waiting.hostStartButton.subtitle" />
							</span>
						</Button>
					)}
				</motion.div>
			</div>
		</motion.div>
	);
}
