import { useCallback } from "react";

import { RoomAction } from "../../types/action";
import { useAddRoomAction } from "./use-add-room-action";

type ActionCreator<Arguments extends unknown[]> = (...args: Arguments) => RoomAction;

export function useCurryAddRoomAction<ActionCreatorArguments extends unknown[]>(
	actionCreator: ActionCreator<ActionCreatorArguments>
) {
	const addAction = useAddRoomAction();

	const addCurriedAction = useCallback(
		(...args: ActionCreatorArguments) => {
			const action = actionCreator(...args);
			addAction(action);
		},
		[addAction, actionCreator]
	);

	return addCurriedAction;
}
