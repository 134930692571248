export const VERSION = __APP_VERSION__;

export const SENTRY = {
	dsn: import.meta.env.VITE_APP_SENTRY_DSN,
	sampleRate: parseFloat(import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE),
} as const;

export const CREATOR = {
	name: __AUTHOR_NAME__,
	link: __AUTHOR_URL__,
} as const;

export const FIREBASE_CONFIG = {
	apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
	authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: import.meta.env.VITE_APP_FIREBASE_DB_URL,
	projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGE_SENDER_ID,
	appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
} as const;
