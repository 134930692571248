"use client";

import { Games } from "@dejarik/games";
import { ConnectToVideoRoom } from "@dejarik/video";
import { AnimatePresence } from "framer-motion";

import { useCurrentRoomStage } from "../hooks/use-current-room-stage";
import { useRoomId } from "../hooks/use-room-id";
import { CantJoin } from "./screens/cant-join";
import { GameVotingScreen } from "./screens/game-voting";
import { JoinScreen } from "./screens/join";
import { LeftRoom } from "./screens/left-room";
import { Lobby } from "./screens/lobby";

export function Room() {
	const roomId = useRoomId();
	const roomStage = useCurrentRoomStage();

	const roomStageIsConnected = roomStage === "game" || roomStage === "game-voting" || roomStage === "lobby";

	return (
		<>
			{roomStage === "player-cant-join" && <CantJoin key="cant-join" />}
			{roomStage === "player-join" && <JoinScreen key="join" />}
			{roomStage === "left-room" && <LeftRoom key="left-room" />}
			{roomStageIsConnected && (
				<ConnectToVideoRoom roomID={roomId}>
					<AnimatePresence mode="wait">
						{roomStage === "lobby" && <Lobby key="lobby" />}
						{roomStage === "game-voting" && <GameVotingScreen key="game-voting" />}
						{roomStage === "game" && <Games key="game" />}
					</AnimatePresence>
				</ConnectToVideoRoom>
			)}
		</>
	);
}
