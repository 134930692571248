const VIDEO_CONSTRAINTS = {
	small: {
		height: 120,
		width: 120,
		facingMode: "user",
	} as MediaTrackConstraints,
	default: {
		height: 240,
		width: 240,
		facingMode: "user",
	} as MediaTrackConstraints,
} as const;

type VideoMode = keyof typeof VIDEO_CONSTRAINTS;

export function getVideoConstraints(mode: VideoMode) {
	return VIDEO_CONSTRAINTS[mode];
}
