import { HTMLAttributes } from "react";
import * as React from "react";

import { FullHeight } from "../components/full-height";

interface DefaultPageProps extends HTMLAttributes<HTMLDivElement> {
	center?: boolean;
	footer?: React.ReactNode;
	header?: React.ReactNode;
}

export function DefaultPage({ header, footer, center = false, children, className }: DefaultPageProps) {
	return (
		<FullHeight className={`${className} flex h-full flex-col p-4`}>
			{header && (
				<header className="mb-2 ">
					<h3 className="font-display text-4xl font-medium">{header}</h3>
				</header>
			)}
			<section className={`flex flex-1 flex-col ${center && "justify-center"}`}>{children}</section>

			{footer && <footer>{footer}</footer>}
		</FullHeight>
	);
}
