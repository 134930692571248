"use client";

import { Translate } from "@dejarik/localization";
import {
	createAddPlayerAction,
	LocalPlayerContext,
	useCurryAddRoomAction,
	useUpdatePlayerName,
} from "@dejarik/room-actions";
import { Button } from "@dejarik/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useInvitedBy } from "../../hooks/use-invited-by";
import { CaptureCameraPermissions } from "../capture-camera-permissions";
import { CapturePlayerName } from "../capture-player-name";
import { CaptureStreamingTracksPreviews } from "../capture-streaming-previews";
import { RoomLink } from "../room-link";

const JoinFormSchema = z.object({
	playerName: z.string().min(2),
	cameraPermission: z.literal("granted"),
});

type JoinFormData = z.infer<typeof JoinFormSchema>;

export function JoinScreen() {
	const localPlayerContext = useContext(LocalPlayerContext);
	const updatePlayerName = useUpdatePlayerName();
	const addPlayer = useCurryAddRoomAction(createAddPlayerAction);
	const invitedBy = useInvitedBy();

	const methods = useForm<JoinFormData>({
		resolver: zodResolver(JoinFormSchema),
		defaultValues: {
			playerName: localPlayerContext?.player?.name,
		},
	});
	const onSubmit = (data: JoinFormData) => {
		const player = updatePlayerName(data.playerName);
		addPlayer(player);
	};

	return (
		<div className="bg-background @container relative h-full w-full overflow-scroll p-1">
			<div className="@lg:gap-12 @lg:items-center @lg:justify-center flex h-full flex-col gap-4">
				<div className="@lg:flex-initial flex-1">
					<h1 className="text-foreground @lg:text-center text-7xl font-extrabold leading-[4rem] tracking-tight">
						<Translate k="captureInformation.headline" />
					</h1>
				</div>
				<div className="@lg:w-96">
					<div className="text-muted-foreground text-sm">
						{invitedBy && (
							<div>
								<Translate k="captureInformation.invitedBy" name={<b>{invitedBy}</b>} />{" "}
							</div>
						)}
						<Translate k="captureInformation.description" />{" "}
						<RoomLink target="explainerPage" className="underline hover:cursor-pointer">
							<Translate k="captureInformation.explanationLabel" />
						</RoomLink>
					</div>
					<div className="text-foreground mt-1">
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)}>
								<CapturePlayerName />
								<div className="mt-6">
									<CaptureCameraPermissions />
								</div>
								<div className="mt-2">
									<CaptureStreamingTracksPreviews />
								</div>
								<div className="mt-2">
									<Button className="w-full" type="submit">
										Join
									</Button>
								</div>
							</form>
						</FormProvider>
					</div>
				</div>
			</div>
		</div>
	);
}
