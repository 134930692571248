import { Howl, HowlOptions } from "howler";
import { useEffect, useState } from "react";

export function useHowl(options: HowlOptions) {
	const [howl, setHowl] = useState<Howl | undefined>();

	useEffect(() => {
		const howl = new Howl(options);

		setHowl(howl);
		return () => {
			howl.unload();
			setHowl(undefined);
		};
	}, [options]);

	return howl;
}
