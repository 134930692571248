import { ReactNode, useMemo } from "react";

import { useTwilioVideoStreamingAPI } from "../hooks/twilio/use-twilio-video-streaming-api";
import { useTwilioVideoStreamingPreviewAPI } from "../hooks/twilio/use-twilio-video-streaming-preview-api";
import { createVideoStreamingAPI } from "../utils/create-video-streaming-api";
import { createVideoStreamingPreviewAPI } from "../utils/create-video-streaming-preview-api";
import { VideoStreamingAPIProvider } from "./video-streaming-api";
import { VideoStreamingPreviewAPIProvider } from "./video-streaming-preview-api";

interface CompleteTwilioVideoStreamingAPIProviderProps {
	getAccessToken: (userId: string, abortSignal: AbortSignal) => Promise<string>;
	children: ReactNode;
}

export function CompleteTwilioVideoStreamingAPIProvider({
	getAccessToken,
	children,
}: CompleteTwilioVideoStreamingAPIProviderProps) {
	const videoStreamingPreviewProviderAPI = useTwilioVideoStreamingPreviewAPI();
	const videoStreamingPreviewAPI = useMemo(
		() => createVideoStreamingPreviewAPI(videoStreamingPreviewProviderAPI),
		[videoStreamingPreviewProviderAPI]
	);

	const videoStreamingProviderAPI = useTwilioVideoStreamingAPI(getAccessToken, videoStreamingPreviewAPI);
	const videoStreamingAPI = useMemo(
		() => createVideoStreamingAPI(videoStreamingProviderAPI),
		[videoStreamingProviderAPI]
	);
	return (
		<VideoStreamingPreviewAPIProvider videoStreamingPreviewAPI={videoStreamingPreviewAPI}>
			<VideoStreamingAPIProvider videoStreamingAPI={videoStreamingAPI}>{children}</VideoStreamingAPIProvider>
		</VideoStreamingPreviewAPIProvider>
	);
}
