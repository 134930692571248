import { Game, GameTitle } from "@dejarik/games";
import { GameDescriptor } from "@dejarik/games/src/game-descriptor";
import { Badge } from "@dejarik/ui";
import { cn, secondsToMinutes } from "@dejarik/utils";
import { motion } from "framer-motion";
import { Timer, Users } from "lucide-react";
import { useCallback } from "react";

interface GameVotingButtonProps {
	game: Game;
	isDisabled?: boolean;
	isSelected?: boolean;
	isSelectionDisabled?: boolean;
	isVoted?: boolean;
	status: GameDescriptor["status"];
	onSelect?: (gameId: Game["id"]) => void;
	numberOfVotes: number;
}

export function GameVotingButton({
	game,
	isDisabled = false,
	isSelected = false,
	isSelectionDisabled = false,
	isVoted = false,
	status,
	onSelect,
	numberOfVotes,
}: GameVotingButtonProps) {
	const handleClick = useCallback(() => {
		onSelect?.(game.id);
	}, [game.id]);

	const durationInMinutes = Math.round(secondsToMinutes(game.getGameLengthInSeconds(4)));

	return (
		<button
			className={cn("relative grid w-full place-content-center rounded p-4", {
				["bg-amber-100  outline outline-4 outline-offset-2 outline-amber-400"]: isSelected,
				["bg-purple-100"]: !isSelected && isVoted,
				["bg-neutral-100"]: !isSelected && !isVoted && !isDisabled,
				["bg-neutral-50 text-neutral-400"]: !isSelected && isDisabled,
			})}
			onClick={!isSelectionDisabled ? handleClick : undefined}
			disabled={isDisabled || isSelectionDisabled}
		>
			<div className="absolute -right-2 -top-2">
				{numberOfVotes > 0 && (
					<motion.div
						key={numberOfVotes}
						initial={{ y: -4 }}
						animate={{ y: 0 }}
						className="grid h-6 w-6 place-content-center rounded-full bg-purple-600 font-medium tabular-nums text-white"
					>
						{numberOfVotes}
					</motion.div>
				)}
			</div>
			<motion.div
				animate={{ scale: isSelected ? 0.9 : 1 }}
				transition={{ type: "spring", stiffness: 900, damping: 28 }}
				className="flex flex-col items-center gap-2"
			>
				{status === "alpha" && <Badge className={cn({ ["opacity-60"]: isDisabled })}>Alpha</Badge>}
				{status === "beta" && (
					<Badge variant="outline" className={cn({ ["opacity-60"]: isDisabled })}>
						Beta
					</Badge>
				)}
				<div className="flex items-center gap-2">
					<GameTitle gameId={game.id} className="font-medium" />
				</div>
				<div className="flex flex-col">
					<div className="flex items-center gap-2 p-1">
						<Users className="h-4 w-4" />
						<span className="text-sm">
							{game.playerCount.min === game.playerCount.max
								? game.playerCount.min
								: `${game.playerCount.min} - ${game.playerCount.max}`}
						</span>
					</div>
					<div className="flex items-center gap-2 p-1">
						<Timer className="h-4 w-4" />
						<span className="text-sm">~{durationInMinutes}m</span>
					</div>
				</div>
			</motion.div>
		</button>
	);
}
