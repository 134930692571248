import { ReactNode, useState } from "react";

import { LanguageContext } from "..";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../constants";

interface DefaultLanguageProviderProps {
	useStateHook?: typeof useState;
	children: ReactNode;
}

export function DefaultLanguageProvider({ useStateHook = useState, children }: DefaultLanguageProviderProps) {
	const [selectedLanguage, setSelectedLanguage] = useStateHook(DEFAULT_LANGUAGE);

	return (
		<LanguageContext.Provider
			value={{ selectedLanguage, setSelectedLanguage, availableLanguages: AVAILABLE_LANGUAGES }}
		>
			{children}
		</LanguageContext.Provider>
	);
}
