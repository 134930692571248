import {
	areAllPlayersReady,
	canPlayerJoin,
	filterActions,
	getPlayers,
	getRoomStages,
	isPlayerInRoom,
	isSamePlayer,
	Player,
	RoomAction,
} from "@dejarik/room-actions";
import invariant from "tiny-invariant";

type RoomStage = "game" | "player-join" | "game-voting" | "player-cant-join" | "lobby" | "left-room";

export function getCurrentRoomStage(localPlayer: Player | undefined, roomActions: RoomAction[]): RoomStage {
	const players = getPlayers(roomActions);

	const roomStages = getRoomStages(roomActions);

	const playerIsInRoom = localPlayer ? isPlayerInRoom(roomActions, localPlayer) : false;

	if (localPlayer && !playerIsInRoom) {
		const removePlayerActions = filterActions(roomActions, "setup/remove-player");
		const playerHasBeenRemoved = removePlayerActions.some((action) =>
			isSamePlayer({ id: action.playerId }, localPlayer)
		);
		if (playerHasBeenRemoved) {
			return "left-room";
		}
	}

	const currentStage = roomStages.at(-1);
	invariant(currentStage);

	if (currentStage.stage === "game") return "game";

	const gameHasBeenStarted = filterActions(currentStage.actions, "setup/start").length > 0;
	if (gameHasBeenStarted) return "game-voting";

	const playerCanJoin = canPlayerJoin(roomActions);

	if (!localPlayer || (!playerIsInRoom && playerCanJoin)) return "player-join";
	if (gameHasBeenStarted && areAllPlayersReady(players, currentStage.actions)) return "game-voting";
	if (!playerIsInRoom && !playerCanJoin) return "player-cant-join";

	return "lobby";
}
