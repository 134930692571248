const VIDEO_CONSTRAINTS = {
	small: {
		height: 160,
		width: 160,
		facingMode: "user",
	},
} as const;

type VideoMode = keyof typeof VIDEO_CONSTRAINTS;

export async function getMediaStream(mode: VideoMode = "small") {
	return navigator.mediaDevices.getUserMedia({
		video: VIDEO_CONSTRAINTS[mode],
		audio: true,
	});
}
