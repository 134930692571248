import { getPlayers } from "../../room-state-selectors/get-players/get-players";
import { RoomAction } from "../../types/action";
import { RoomStage } from "../../types/room-stage";
import { getNextRoomStage } from "../get-next-room-stage/get-next-room-stage";
import { getRoomStageResult } from "../get-room-stage-result/get-room-stage-result";

export function getRoomStages(roomActions: RoomAction[]): RoomStage[] {
	const allActions: RoomAction[] = [];
	const currentActions: RoomAction[] = [];
	const stages: RoomStage[] = [
		{
			actions: [],
			stage: "setup",
		},
	];

	for (let i = 0; i < roomActions.length; i++) {
		const currentAction = roomActions[i];
		allActions.push(currentAction);
		currentActions.push(currentAction);

		const currentPlayers = getPlayers(allActions);
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const currentStage = stages.at(-1)!;
		currentStage.actions.push(currentAction);

		const currentStageResult = getRoomStageResult(currentPlayers, currentStage);
		if (currentStageResult) {
			stages.push({
				actions: [],
				stage: getNextRoomStage(currentStageResult),
			});
		}
	}

	return stages;
}
