import { Translate } from "@dejarik/localization";
import { cn } from "@dejarik/utils";
import { useVideoStreamingPreviewAPI } from "@dejarik/video";
import { Video, VideoOff } from "lucide-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { PreviewStreamingTracks } from "./video-streaming-preview";

export function CaptureStreamingTracksPreviews() {
	const videoStreamingPreviewAPI = useVideoStreamingPreviewAPI();

	const { watch } = useFormContext();
	const cameraPermission = watch("cameraPermission") as PermissionState;

	useEffect(() => {
		if (cameraPermission === "granted" && videoStreamingPreviewAPI.status === "idle") {
			videoStreamingPreviewAPI.start();
		}
	}, [cameraPermission, videoStreamingPreviewAPI]);

	return (
		<div>
			<div
				className={cn("relative aspect-square w-full overflow-hidden rounded-lg text-white", {
					["bg-gray-800"]: cameraPermission !== "denied",
					["bg-red-400 text-white"]: cameraPermission === "denied",
				})}
			>
				{cameraPermission === "prompt" && (
					<div className="flex h-full w-full flex-col items-center justify-center gap-4 p-8">
						<Video size={48} className="text-gray-700" />
						<span className="z-0 text-center text-sm text-neutral-300">
							<Translate k="captureInformation.promptVideoPermissionDescription" />
						</span>
					</div>
				)}
				{cameraPermission === "denied" && (
					<div className="flex h-full w-full flex-col items-center justify-center gap-4 p-8">
						<VideoOff size={48} className="text-red-300" />
						<span className="z-0 text-center text-sm">
							<Translate k="captureInformation.deniedVideoPermissionWarningDescription" />
						</span>
					</div>
				)}
				{cameraPermission === "granted" && videoStreamingPreviewAPI.status === "ready" && (
					<PreviewStreamingTracks
						videoTrack={videoStreamingPreviewAPI.mediaTracks.video}
						audioTrack={videoStreamingPreviewAPI.mediaTracks.audio}
						selectVideoDevice={videoStreamingPreviewAPI.mediaTracks.setVideoDevice}
						selectAudioDevice={videoStreamingPreviewAPI.mediaTracks.setAudioDevice}
						PreviewStream={videoStreamingPreviewAPI.PreviewStream}
					/>
				)}
			</div>
		</div>
	);
}
