import { lazy } from "react";

import { GameDescriptor } from "../game-descriptor";

export const battleWordleGameDescriptor = {
	id: "battle-wordle",
	status: "stable",
	playerCount: {
		min: 2,
		max: 12,
	},
	getGameLengthInSeconds: () => 60 * 10,
	game: lazy(() => import("./game")),
} as const satisfies GameDescriptor;
