import { v4 } from "@dejarik/utils";
import { useCallback } from "react";

import { Player } from "../../types/player";
import { useLocalPlayerContext } from "./use-local-player-context";

export function useUpdatePlayerName() {
	const { player, setPlayer } = useLocalPlayerContext();

	const updatePlayerName = useCallback(
		(playerName: string) => {
			let newPlayer: Player;
			if (!player) {
				newPlayer = {
					name: playerName,
					id: v4(),
					isFake: false,
				};
			} else {
				newPlayer = {
					...player,
					name: playerName,
				};
			}

			setPlayer(newPlayer);
			return newPlayer;
		},
		[player, setPlayer]
	);

	return updatePlayerName;
}
