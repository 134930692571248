"use client";

import { createContext } from "react";

import { RoomAction } from "../types/action";

type RoomActionsContext = {
	roomActions: RoomAction[];
	addAction: (action: RoomAction) => void;
};

export const RoomActionsContext = createContext<RoomActionsContext | undefined>(undefined);
