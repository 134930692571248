import { ReactNode, useCallback, useMemo, useState } from "react";

import { SoundContext, SoundContextType, SoundSettings, SoundType } from "../state/sound-context";

interface SoundContextProviderProps {
	isMuted?: boolean;
	children: ReactNode;
}

export function SoundContextProvider({ isMuted, children }: SoundContextProviderProps) {
	const [soundSettings, setSoundSettings] = useState<SoundContextType["settings"]>({
		effects: {
			isMuted: false,
			volume: 1,
		},
		music: {
			isMuted: false,
			volume: 0.1,
		},
		"voice-comms": {
			isMuted: false,
			volume: 1,
		},
	});

	const handleSetSoundSettings = useCallback(
		(type: SoundType, settings: SoundSettings) => {
			setSoundSettings((allSoundSettings) => ({ ...allSoundSettings, [type]: settings }));
		},
		[setSoundSettings]
	);

	const settings = useMemo(() => {
		const newSettings = { ...soundSettings };

		newSettings.effects.isMuted = isMuted ?? newSettings.effects.isMuted;
		newSettings.music.isMuted = isMuted ?? newSettings.music.isMuted;
		newSettings["voice-comms"].isMuted = isMuted ?? newSettings["voice-comms"].isMuted;

		return newSettings;
	}, [soundSettings, isMuted]);

	return (
		<SoundContext.Provider value={{ settings, setSoundSettings: handleSetSoundSettings }}>
			{children}
		</SoundContext.Provider>
	);
}
