import { RoomStage } from "../../types/room-stage";
import { StageResult } from "../get-room-stage-result/get-room-stage-result";

export function getNextRoomStage(previousStageResults: StageResult): RoomStage["stage"] {
	if (previousStageResults === "ready-to-game") {
		return "game";
	}

	if (previousStageResults === "replay-game") {
		return "game";
	}

	if (previousStageResults === "return-to-lobby") {
		return "setup";
	}

	throw new Error("stage result is not supported");
}
