import { RoomAction } from "../types/action";
import { Player } from "../types/player";
import { filterActions } from "./filter-actions/filter-actions";
import { isSamePlayer } from "./is-same-player/is-same-player";

export function areAllPlayersReady(players: Player[], sessionActions: RoomAction[]) {
	const readyActions = filterActions(sessionActions, "setup/player-is-ready");

	return players.every((player) =>
		readyActions.some((readyAction) => isSamePlayer(player, { id: readyAction.playerId }))
	);
}
