"use client";

import { useContext } from "react";
import invariant from "tiny-invariant";

import { SharingContext } from "../state/sharing-context";

export function useSharingContext() {
	const sharingContext = useContext(SharingContext);
	invariant(sharingContext, "SharingContext.Provider is missing from the tree");

	return sharingContext;
}
