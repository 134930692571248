import { RoomAction } from "../types/action";
import { getRoomStages } from "../utils/get-room-stages/get-room-stages";
import { getVotedGameFromSetupActions } from "../utils/get-voted-game-from-setup-actions";

export function currentGameIdSelector(roomActions: RoomAction[]) {
	const stages = getRoomStages(roomActions);

	const currentStage = stages.at(-1);
	if (!currentStage) return undefined;
	if (currentStage.stage !== "game") return undefined;

	const lastSetupStage = [...stages].reverse().find((stage) => stage.stage === "setup");
	if (!lastSetupStage) return undefined;

	return getVotedGameFromSetupActions(lastSetupStage.actions);
}
