import invariant from "tiny-invariant";

import { VideoStreamingPreviewAPI } from "../types/video-streaming-preview-api";
import { VideoStreamingPreviewProviderAPI } from "../types/video-streaming-preview-provider-api";

export function createVideoStreamingPreviewAPI({
	status,
	start,
	stop,
	error,
	mediaTracks,
	PreviewStream,
}: VideoStreamingPreviewProviderAPI): VideoStreamingPreviewAPI {
	if (status === "idle") {
		return {
			status: "idle",
			start,
			PreviewStream,
		};
	}

	if (status === "starting") {
		return {
			status: "starting",
			PreviewStream,
		};
	}

	if (status === "error") {
		invariant(error);
		return {
			status: "error",
			error,
			PreviewStream,
		};
	}

	invariant(mediaTracks);

	return {
		status: "ready",
		mediaTracks,
		stop,
		PreviewStream,
	};
}
