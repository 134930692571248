import { currentGameIdSelector, useSelectRoomActionsStateFromPresent } from "@dejarik/room-actions";
import { Suspense, useMemo } from "react";
import invariant from "tiny-invariant";

import { games } from ".";

function LoadingGame() {
	return (
		<div className="grid h-full place-content-center">
			<h1 className="font-medium">Loading Game</h1>
		</div>
	);
}

export function Games() {
	const gameId = useSelectRoomActionsStateFromPresent(currentGameIdSelector);
	const Game = useMemo(() => {
		return games.find((gameDescriptor) => gameDescriptor.id === gameId);
	}, [gameId]);

	invariant(Game);

	const GameComponent = Game.game;

	return (
		<Suspense fallback={<LoadingGame />}>
			<GameComponent />
		</Suspense>
	);
}
