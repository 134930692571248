"use client";

import { ReactNode, useCallback, useState } from "react";
import { z } from "zod";

import { LocalPlayerContext } from "../state/local-player";
import { Player } from "../types/player";

const LocalPlayerSchema = z.object({
	id: z.string(),
	name: z.string(),
	isFake: z.boolean().optional(),
});

function getLocalStorageLocalPlayer(key: string) {
	const localPlayerString = localStorage.getItem(key);
	if (!localPlayerString) {
		return undefined;
	}

	return LocalPlayerSchema.parse(JSON.parse(localPlayerString));
}

function safeLocalStorageLocalPlayer(key: string, player: Player) {
	localStorage.setItem(key, JSON.stringify(player));
}

interface LocalPlayerProviderProps {
	localStorageKey: string;
	children: ReactNode;
}

export function LocalStorageLocalPlayerProvider({ localStorageKey, children }: LocalPlayerProviderProps) {
	const [localPlayer, setLocalPlayer] = useState<Player | undefined>(getLocalStorageLocalPlayer(localStorageKey));

	const setPlayer = useCallback(
		(player: Player) => {
			safeLocalStorageLocalPlayer(localStorageKey, player);
			setLocalPlayer(player);
		},
		[setLocalPlayer]
	);

	return (
		<LocalPlayerContext.Provider value={{ player: localPlayer, setPlayer }}>{children}</LocalPlayerContext.Provider>
	);
}
