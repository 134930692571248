import { RoomIDContext } from "../state/room-id";

interface RoomIDProviderProps {
	roomID: string;
	children: React.ReactNode;
}

export function RoomIDProvider({ roomID, children }: RoomIDProviderProps) {
	return <RoomIDContext.Provider value={roomID}>{children}</RoomIDContext.Provider>;
}
