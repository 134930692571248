import { english } from "./translations/english";
import { german } from "./translations/german";

export type Translation = typeof english;

export const SUPPORTED_TRANSLATIONS = {
	en: english,
	de: german,
};

export type SupportedLanguage = keyof typeof SUPPORTED_TRANSLATIONS;
export const SUPPORTED_LANGUAGES: SupportedLanguage[] = Object.keys(
	SUPPORTED_TRANSLATIONS
) as SupportedLanguage[];
