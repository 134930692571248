import { RoomAction } from "@dejarik/room-actions";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FIREBASE_CONFIG } from "../../../config";
import { addFirebaseAction } from "../utils/add-action";
import { getRoomActionsFirebaseRef } from "../utils/get-room-actions-ref";
import { listenToFirebaseStateChanges } from "../utils/listen-to-state-changes";

export function useFirebaseRoomActions(roomID: string) {
	const [app] = useState(initializeApp(FIREBASE_CONFIG));
	const [roomActions, setRoomActions] = useState<RoomAction[]>([]);

	const firebaseDB = useMemo(() => getDatabase(app), [app]);
	const addAction = useCallback(
		(action: RoomAction) => addFirebaseAction(firebaseDB, roomID, action),
		[roomID, firebaseDB]
	);

	useEffect(() => {
		const roomActionsRef = getRoomActionsFirebaseRef(roomID);

		const off = listenToFirebaseStateChanges<RoomAction[]>(
			firebaseDB,
			roomActionsRef,
			(actions) => {
				if (!actions) return;

				const correctedActions = actions?.map((action) => {
					return action;
				});

				setRoomActions(correctedActions);
			},
			"list"
		);

		return () => {
			off();
			setRoomActions([]);
		};
	}, [firebaseDB, roomID]);

	return {
		roomActions,
		addAction,
	};
}
