import { GenericPlayer } from "../../types/player";

type OptionalGenericPlayer = GenericPlayer | undefined;

export function isSamePlayer<Players extends OptionalGenericPlayer[]>(...players: Players) {
	if (!players[0]) return false;

	const playerId = players[0].id;

	return players.every((player) => player?.id === playerId);
}
