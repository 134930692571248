import { LocalParticipant, RemoteParticipant } from "twilio-video";

import { isVisualTrack } from "./is-visual-track";
import { VisualTrack } from "./track-types";

export function getVisualTracksFromLocalParticipant(participant: LocalParticipant): VisualTrack[] {
	const trackPublications = Array.from(participant.tracks.values());
	const tracks = trackPublications.map((pub) => pub.track).filter(isVisualTrack) as VisualTrack[];

	return tracks;
}

export function getVisualTracksFromRemoteParticipant(remoteParticipant: RemoteParticipant): VisualTrack[] {
	const publications = Array.from(remoteParticipant.tracks.values());
	const subscribedPublications = publications.filter((pub) => pub.isSubscribed);

	return (
		subscribedPublications
			.map((pub) => pub.track)
			.filter(Boolean)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			.filter(isVisualTrack) as VisualTrack[]
	);
}
