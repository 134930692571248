import * as Sentry from "@sentry/react";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import { SENTRY, VERSION } from "../../config";

export function setupSentry() {
	if (import.meta.env.VITE_APP_ENVIRONMENT === "development") return;

	Sentry.init({
		release: VERSION,
		environment: import.meta.env.VITE_APP_ENVIRONMENT,
		dsn: SENTRY.dsn,
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
		],
		tracesSampleRate: SENTRY.sampleRate,
	});
}
