import { Translate } from "@dejarik/localization";
import { Button } from "@dejarik/ui";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { DefaultPage } from "../ui/pages/default";

export function ExplainerPage() {
	const navigate = useNavigate();

	return (
		<DefaultPage>
			<div className="container flex flex-1 flex-col">
				<div className="-ml-6">
					<Button onClick={() => navigate(-1)} variant="ghost" className="flex items-center gap-2">
						<ChevronLeft /> Back to room
					</Button>
				</div>
				<div className="mt-2">
					<h1 className="text-2xl font-semibold">
						<Translate k="explainerPage.headline" />
					</h1>
					<p className="mt-2">
						<Translate k="explainerPage.description" />
					</p>
				</div>
			</div>
		</DefaultPage>
	);
}
