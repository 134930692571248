import { RoomAction } from "@dejarik/room-actions";
import { type Database, push, ref } from "firebase/database";

import { getRoomActionsFirebaseRef } from "./get-room-actions-ref";

export function addFirebaseAction(firebaseDB: Database, roomId: string, action: RoomAction) {
	const roomActionsRef = ref(firebaseDB, getRoomActionsFirebaseRef(roomId));

	return push(roomActionsRef, action);
}
