import { useContext } from "react";

import { VideoStreamingAPIContext } from "../state/video-streaming-api";

export function useVideoError() {
	const videoStreamingAPI = useContext(VideoStreamingAPIContext);

	if (!videoStreamingAPI) {
		return null;
	}

	if (videoStreamingAPI.status !== "error") {
		return null;
	}

	return videoStreamingAPI.error;
}
