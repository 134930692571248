import { useContext } from "react";
import invariant from "tiny-invariant";

import { RoomIDContext } from "../state/room-id";

export function useRoomId() {
	const roomId = useContext(RoomIDContext);
	invariant(roomId, "RoomIDContext.Provider is missing from tree");

	return roomId;
}
