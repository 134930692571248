import invariant from "tiny-invariant";

import { VideoStreamingAPI } from "../types/video-streaming-consumer-api";
import { VideoStreamingProviderAPI } from "../types/video-streaming-provider-api";

export function createVideoStreamingAPI({
	status,
	error,
	join,
	disconnect,
	mediaTracks,
	PlayerStream,
}: VideoStreamingProviderAPI): VideoStreamingAPI {
	if (status === "idle") {
		return {
			status: "idle",
			join,
			PlayerStream,
		};
	}

	if (status === "connecting") {
		return {
			status: "connecting",
			disconnect,
			PlayerStream,
		};
	}

	if (status === "error") {
		invariant(error);
		return {
			status: "error",
			error,
			PlayerStream,
		};
	}

	invariant(mediaTracks);

	return {
		status: "connected",
		mediaTracks,
		disconnect,
		PlayerStream,
	};
}
