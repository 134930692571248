import { LocalTrack, RemoteTrack } from "twilio-video";

import { AnyVideoTrack, VisualTrack } from "./track-types";

export function isVisualTrack(track: LocalTrack | RemoteTrack): track is VisualTrack {
	return track.kind !== "data";
}

export function isVideoTrack(track: LocalTrack | RemoteTrack): track is AnyVideoTrack {
	return track.kind === "video";
}
