import { useValueFromPresent } from "@dejarik/utils";

import { RoomAction } from "../../types/action";
import { useSelectRoomActionsState } from "./use-select-room-actions-state";

type StateSelectorFunction<State, SelectorArguments extends unknown[]> = (
	roomActions: RoomAction[],
	...args: SelectorArguments
) => State;

export function useSelectRoomActionsStateFromPresent<State, SelectorArguments extends unknown[]>(
	getState: StateSelectorFunction<State, SelectorArguments>,
	...args: SelectorArguments
) {
	const state = useSelectRoomActionsState(getState, ...args);
	const stateFromPresent = useValueFromPresent(state);

	return stateFromPresent;
}
